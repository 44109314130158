<template>
  <div class="home">
    <HeaderSectionNEW />
    <SideBarNew/>
    <SideBarBotMobile/>
    <BannerSection />
    <GamesCategory />
    <CasinoAreaGames/>
    <FooterSection />
  </div>
</template>


<script>
import HeaderSectionNEW from "@/components/HeaderSectionNEW.vue";
import SideBarBotMobile from "@/components/SideBarBotMobile.vue"
import SideBarNew from "@/components/SideBarNew.vue";
import BannerSection from "@/components/BannerSection.vue";
import GamesCategory from "@/components/CasinoPage/MenuCategoryCasino.vue";
import CasinoAreaGames from "@/components/CasinoPage/CasinoAreaGames.vue"

import FooterSection from "@/components/FooterSection.vue";
import 'animate.css';
import "@fontsource/inter";


export default {
  name: "CasinoPage",
  components: {
    HeaderSectionNEW,
    SideBarBotMobile,
    SideBarNew,
    BannerSection,
    GamesCategory,
    CasinoAreaGames,
    FooterSection,
    
    
  },
};
</script>