<template>
  <div class="infoArea">
    <div class="container text-light">
      <div class="windowInfo">
        <div class="row">
          <div class="col-xl-4">
            <div class="areaNavigate">

              <a href="/contacts/contactus">
                <div class="contactUS field__space">Связатся с нами</div>
              </a>

              <!-- <div class="blog field__space">Блог</div> -->

              <a href="/contacts/politics">
                <div class="politicsConf field__space">Политикой Конфиденциальности</div>
              </a>

              <a href="/contacts/respons">
                <div class="gameRespons field__space">Responsible Gambling</div>
              </a>
              <a href="/contacts/fairgames">
                <div class="rulesGame field__space">Честная игра</div>
              </a>
              <a href="/contacts/rules">
                <div class="contactUS field__space active">Правила игр</div>
              </a>
              <a href="/contacts/terms">
              <div class="termsCondit field__space">Условиями и положениями</div>
            </a>

            </div>
          </div>
          <div class="col-xl-8 col-l2">
            <div class="textArea">
              <div class="row">
                <h1>Правила игр</h1>

                <p>&nbsp;</p>

                <h3><strong>Games rules</strong></h3>

                <h3>AMATIC</h3>

                <h4>Slots</h4>

                <p>Games of this type always possess a number of reels (from 3 to 6), which the player has to spin in
                  order to gain matching combinations of symbols. These combinations depend on particular game design. As
                  soon as the desired stakes have been made the game is started with pressing the &ldquo;START&quot;
                  button. After the reel run the achieved winning combination is displayed among others by illuminated win
                  lines and the winning is added to the player&rsquo;s balance. Afterwards bets can be placed again. In
                  order to get acquainted with the win plan and respective symbol meanings &ldquo;HELP&quot; button is to
                  assist. Some of the games include bonus spins, which become available for the player after a particular
                  game event. This means a player can make a particular amount of free spins with no balance
                  changes.<br />
                  The player has the option to activate the gamble mode before each game which gives the opportunity to
                  multiply the winnings.<br />
                  If the gamble mode is active, a screen opens when achieving a win, where the player has to guess whether
                  the next card is black or red. At the beginning the total win is placed for bet, but the player has the
                  option to bet only half the stake, resulting in having half of the win added to the credit account. The
                  bet is called risk here. The player can also see the displayed card colors of the seven previous
                  gambles. When the player chooses black or red, he has the chance to double the win. The win can also be
                  multiplied by choosing the suit &ndash; hearts, diamonds, spades or clubs. When the player has made a
                  decision, the card is uncovered. If the guess is correct, the player wins. If it was wrong, the stake is
                  lost. The player can multiply the winning with further gambles as long as the correct color is chosen.
                  The player can quit the gamble any time by pressing the COLLECT button, the win will then be credited to
                  the account. If the player loses, the game continues.</p>

                <h3>BETSOFT</h3>

                <h4>Slots</h4>

                <p>The goal of playing is to spin the reels until the combination of symbols on them matches the winning
                  kind. The game starts with the betting process, coin value and betting level are adjustable. After the
                  stake is made the green starting button is to be pressed on the bottom of the interface. Symbol
                  interpretations and payout order are under the button &ldquo;?&quot;. In case of successful spin the
                  winnings are credited to the player&rsquo;s account and a new stake can be made. Freeround mode is
                  available after the particular event takes place.The number of payable lines may differ from 5 to 40
                  (maximum 1024 ways).<br />
                  Some of the games provide a possibility to double the winnings by clicking the &ldquo;Double up&quot;
                  button at the bottom right corner on the main game user interface. Then the player is taken to the
                  secondary screen where he plays heads and trials and gets the possibility to increase his winnings in
                  case of luck.</p>

                <h4>Poker</h4>

                <p>Poker is usually played with a deck of 52 cards, but it may vary. The goal of the game is to win the
                  bets by gathering the highest poker combination of cards. These combinations are ranked from the highest
                  to the lowest one in the following order: Royal Flush, Straight Flush, Four of a Kind, Full House,
                  Flush, Straight, Three of a Kind, Two Pairs, Pair, High card.<br />
                  If the combination is the same by players, high cards should be compared. Each dealing session has
                  several rounds. In order to participate in the game, an &ldquo;ante&quot; bet should be placed (or
                  &ldquo;blind&quot; in some variations). Players in a poker game act in turn, in clockwise rotation. Each
                  player may &ldquo;fold,&quot; which is to drop out of the hand losing any bets they have already made or
                  &ldquo;call,&quot; which is to match the highest bet so far made. &ldquo;Raise,&quot; means to increase
                  the previous high bet. Different game types offer various options, such as cards exchanging, playing
                  against dealer independently from other players, playing with tree cards per hand, special card
                  ranking,<br />
                  The min bet is 1 chip, whereas the maximum is 100 chips in the majority of games (in some kinds maximum
                  bet reaches 3500 coins).</p>

                <h4>Craps</h4>

                <p>The first roll of the dice in a craps round is called the &ldquo;come out roll.&quot; The basic opening
                  bet in craps, placed just before the come out roll, is called the &ldquo;pass line bet.&quot; Pass line
                  bets immediately win when the shooter&rsquo;s come out roll is 7 or 11, and lose when the come out roll
                  is 2 (snake eyes), 3 (cross eyes), or 12 (box cars). If 4, 5, 6, 8, 9, or 10 is rolled on the come out
                  roll, that number becomes &ldquo;the point&quot;.<br />
                  The puck labeled ON shows that the point has been established, and is located on the Craps table over
                  the box for the number rolled as the point: 4, 5, SIX, 8, NINE, or 10. The shooter now keeps rolling the
                  dice until he rolls the point or 7 to end the round. If the shooter rolls the point first, a pass line
                  bet wins. If, however, the shooter rolls a 7 first (i.e. &ldquo;sevens out&quot;), a pass line bet
                  loses. To end a round of Craps and resolve pass line bets, the shooter must roll either the point or 7
                  after the come out roll. There&rsquo;s always a possibility that a Craps game can go on all night if the
                  shooter fails to roll the point or 7. Pass line bets can&rsquo;t win until the point is rolled, and
                  can&rsquo;t lose until 7 is rolled. Because unresolved pass line bets may not be removed, players must
                  wait for a roll of the point or 7 to determine the fate of their pass line bets.<br />
                  Before the dice are rolled, bets can be placed in the various fields of the craps table. First, the
                  value of the chip should be chosen. Next, the chip is placed in the desired betting area on the Craps
                  table by clicking in the area with the left mouse button. When the betting process is finished,
                  &ldquo;ROLL&quot; button should be pressed to roll the dice. There are various types of bets in the
                  game: Pass Line Bets, Come Bets, Odds bets, Field bets, Big 6 and big 8 bets, Place bets, Buy bets, Lay
                  bets, Any 7 Bets, Any 11 bets, Any craps bets, Horn bets, Hardway bets. Tips and payout tables are under
                  the &ldquo;Help&quot; button.<br />
                  Minimum bet is 1 chip, maximum is 100.</p>

                <h4>Roulette</h4>

                <p>Roulette is a game played with a large wheel that contains either 37 or 38 pockets. These stops are
                  numbered from 0 to 36, sometimes 00 pockets are also present. All the pockets are all colored, the
                  zeroes are green, while the other spots are evenly divided between 18 red and 18 black pockets. The
                  dealer spins a ball on the outer rim of the wheel, after which it will eventually fall into one of the
                  numbered spaces. The object for the player is to guess what number the ball will land in. Before each
                  spin, players have the opportunity to place bets around the roulette table. Players can bet on
                  individual numbers or virtually any combination of numbers. There are several ways of betting, each kind
                  covering a different set of numbers: Split, Straight, Street, Six Line, Corner, all black or red
                  numbers, all even or odd numbers, dozens, columns.<br />
                  Minimum bet is 3 chips, maximum is 500 chips.</p>

                <h4>Blackjack</h4>

                <p>Six decks of cards are usually used to play the game. Each player plays only against the dealer
                  independently of other players. The goal of the player is to draw cards until his hand adds up to 21, or
                  comes as close as possible without exceeding 21. If the first two cards produce a total 21, it is called
                  Blackjack. If the total value of cards is closer to 21 than the dealer&rsquo;s, the player wins as much
                  as he has wagered. If a player has Blackjack, he wins 3 to 2 times your bet (assuming the dealer does
                  not also have Blackjack). Aces count as either 1 or 11, dressed cards as 10, and remaining cards
                  according to their number values. If the total of cards exceeds 21, the player loses the wager. If the
                  card totals are the same by player and by dealer, from 17 and up, no one wins and the stakes are
                  returned back. Some game variations offer playing 3 simultaneous hands, adding cards to regular 2-card
                  hands and even playing with a single deck. Minimum bet is 1 chip, maximum is 100 chips.</p>

                <h4>Baccarat</h4>

                <p>The object of Baccarat is to predict which hand will get a point value closest to 9. Scores range from
                  0 to 9, and the hand with the highest point value wins. To participate in the game, the bet is placed on
                  the banker&rsquo;s hand, on the player&rsquo;s hand, or on a tie. Then the player and the banker are
                  each dealt a two-card hand. Following standard baccarat rules, a third card is dealt to the player
                  and/or the banker. If the game does result in a tie, a wager for the banker or the player is
                  returned.All winning wagers for the banker and the player pay even money. However, if you win by betting
                  on the banker, the bank receives 5% commission.If you correctly wager that the two hands will tie, your
                  payoff is 8 to1.<br />
                  * Ace = one point.<br />
                  * Face cards and tens = zero.<br />
                  * All other cards carry the point value stated on the card.<br />
                  If the cards in a hand total more than ten points, simply subtract ten; the remainder is the baccarat
                  point value of the hand. For example: 8+8=16, which counts as a 6 in baccarat; and 8+9=17, which counts
                  as 7.<br />
                  Min bet is 1 chip, maximum is 100 chips.</p>

                <h4>Top card Trumps</h4>

                <p>The game starts with an ante bet. Once the bet is chosen, the DEAL button is clicked. Six (6) regular
                  decks of 52 cards are used. The game is simple: whoever has the higher card wins. When it comes to rank,
                  Aces are high and 2s are low. The game carries on in this fashion until a tie occurs between the dealer
                  and the player. If a tie occurs, the player will be asked if he would like to FOLD or go to WAR. If he
                  chooses to fold, the dealer takes half of the bet and deals a new hand. If he chooses to go to war, the
                  bet is raised by an amount equal to the original bet. The dealer will then deal three cards face down
                  and give the player and dealer another card each. If the player&rsquo;s second card equals or beats the
                  dealer, then the player wins even money on the raise only, and the original wager pushes. If the
                  dealer&rsquo;s second card is greater, the player loses both bets. If there is a tie during war the
                  player wins. Minimum bet is 1 chip, maximum is 100 chips.</p>

                <h4>Red dog</h4>

                <p>The game starts with an ante bet. Once the bet is chosen, the DEAL button is clicked. 8 regular decks
                  of 52 cards are used. Two cards are turned up, with a space between them for another card. The game is
                  simple: predict whether the next card will fall between the two shown. Aces are high, twos are low. If
                  the player is confident that the next card will fall between the two shown, he chooses RIDE to double
                  the bet. If the player is not confident, he chooses STAND to keep the same bet amount. If a
                  player&rsquo;s first two cards are consecutive or a pair, the game is a tie and he gets his money back.
                  If the player&rsquo;s first two cards are a pair and the third makes a three of a kind, the player gets
                  11:1. All payouts are listed under the &ldquo;Help&quot; button. Minimum bet is 1 chip, maximum is 100
                  chips.</p>

                <h4>Draw Hi-Lo</h4>

                <p>First ante bet is made. Once the bet is chosen, the DEAL button is clicked. A regular deck of 52 cards
                  is used. The game is simple: predict whether the next card will be higher or lower rank than the current
                  card. Aces are low, and Kings are high. If the player thinks the next card will have the same rank as
                  the current card, now is the time to choose his wager and place it in the Tie bet circle. The player
                  will still have to make a prediction of LOWER or HIGHER. If the next card follows the prediction, the
                  player will win. If the player made a side bet that there would be a tie and there is no tie, the side
                  bet loses, but may still win on his regular prediction. If, however, the next card does tie, the player
                  wins 10 times the amount of the side bet, and may still continue the normal game. The hand will continue
                  until the player loses, in which case the original bet will be lost and all of the winnings, or the
                  player chooses the CASHOUT option, at which point all of the winnings are added to the credit balance.
                  Each time the player makes a new prediction without cashing out, he/she bets all of the winnings so far.
                  That means that the longer the player goes, the greater the winnings, up to 250 credits. Minimum bet is
                  1 chip, maximum is 100 chips.</p>

                <h3>EVOLUTION</h3>

                <h4>Blackjack</h4>

                <p>8 decks 52 cards each are used to play the game. Each player plays only against the dealer
                  independently of other players. The goal of the player is to draw cards until the hand adds up to 21, or
                  comes as close as possible without exceeding 21. If the first two cards produce a total 21, it is called
                  Blackjack. If the total value of cards is closer to 21 than the dealer&rsquo;s, the player wins as much
                  as he/she has wagered. If a player has Blackjack, he/she wins 3 to 2 times your bet (assuming the dealer
                  does not also have Blackjack). Aces count as either 1 or 11, dressed cards as 10, and remaining cards
                  according to their number values. If the total of cards exceeds 21, the player loses the wager.</p>

                <h4>Casino Holdem</h4>

                <p>The game is played with a standard 52 card deck. The goal is to gather the highest poker card
                  combination from 5 cards, using 2 player&rsquo;s cards and 5 community cards:Royal Flush, Straight
                  Flush, Four of a Kind, Full House, Flush, Straight, Three of a Kind, Two Pairs, Pair, High card. Each
                  player makes an Ante bet and may make an optional bonus bet.The player and dealer are both dealt two
                  cards (dealer&rsquo;s cards face down).Three cards are then dealt to the board and will eventually
                  constitute five cards per hand.After checking his/her cards, the player has to decide: &ldquo;Fold&quot;
                  with no further play losing the Ante bet or &ldquo;Call&quot; to double the Ante bet.If one or more
                  players makes a Call bet the dealer will deal two more cards to the board, for a total of five (seven
                  per hand).Each player&rsquo;s hand is compared with the dealer&rsquo;s.</p>

                <h4>Baccarat</h4>

                <p>The object of Baccarat is to predict which hand will get a point value closest to 9. Scores range from
                  0 to 9, and the hand with the highest point value wins. To participate in the game, the bet is placed on
                  the banker&rsquo;s hand, on the player&rsquo;s hand, or on a tie. Then the player and the banker are
                  each dealt a two-card hand. Following standard baccarat rules, a third card is dealt to the player
                  and/or the banker. If the game does result in a tie, a wager for the banker or the player is
                  returned.However, if you win by betting on the banker, the bank receives 5% commission.If you correctly
                  wager that the two hands will tie, your payoff is 8 to 1.<br />
                  * Ace = one point.<br />
                  * Face cards and tens = zero.<br />
                  * All other cards carry the point value stated on the card.<br />
                  If the cards in a hand total more than ten points, simply subtract ten; the remainder is the baccarat
                  point value of the hand. For example: 8+8=16, which counts as a 6 in baccarat; and 8+9=17, which counts
                  as 7.</p>

                <h4>Roulette</h4>

                <p>Roulette is a game played with a large wheel that contains either 37 or 38 pockets. These stops are
                  numbered from 0 to 36,sometimes 00 pockets are also present. All the pockets are all colored,the zeroes
                  are green, while the other spots are evenly divided between 18 red and 18 black pockets. The dealer
                  spins a ball on the outer rim of the wheel, after which it will eventually fall into one of the numbered
                  spaces. The object for the player is to guess what number the ball will land in.Before each spin,
                  players have the opportunity to place bets around the roulette table. Players can bet on individual
                  numbers or virtually any combination of numbers, there are several ways: for one number, two numbers,
                  three numbers, corner, six numbers, all black or red numbers, all even or odd numbers, dozens, columns,
                  1-18/19-36.</p>

                <h4>Texas Holdem</h4>

                <p>The game is played with a standard 52 card deck. The goal is to gather the highest poker card
                  combination:Royal Flush, Straight Flush, Four of a Kind, Full House, Flush, Straight, Three of a Kind,
                  Two Pairs, Pair, High card. Two cards are dealt face down to each player, and then five community cards
                  are dealt face up in three stages. The stages consist of a series of three cards (&ldquo;the
                  flop&quot;), later an additional single card (&ldquo;the turn&quot;), and a final card (&ldquo;the
                  river&quot;). Each player seeks the best five card poker hand from any combination of the seven cards of
                  the five community cards and their own two hole cards. The &ldquo;ante&quot; bet can be raised only once
                  a round by choosing &ldquo;Play&quot;. &ldquo;Check&quot; means the bet is to stay the same.<br />
                  The kind of Texas Holdem is Caribbean Stud poker, where the game is played against the dealer.</p>

                <h4>Poker</h4>

                <p>Poker is usually played with a deck of 52 cards, but it may vary. The goal of the game is to win the
                  bets by gathering the highest poker combination of cards. These combinations are ranked from the highest
                  to the lowest one in the following order: Royal Flush, Straight Flush, Four of a Kind, Full House,
                  Flush, Straight, Three of a Kind, Two Pairs, Pair, High card. If the combination is the same by players,
                  high cards should be compared. Each dealing session has several rounds. In order to participate in the
                  game, an &ldquo;ante&quot; bet should be placed (or &ldquo;blind&quot; in some variations). Players in a
                  poker game act in turn, in clockwise rotation. Each player may &ldquo;fold,&quot; which is to drop out
                  of the hand losing any bets they have already made or &ldquo;call,&quot; which is to match the highest
                  bet so far made. &ldquo;Raise,&quot; means to increase the previous high bet. Different game types offer
                  various options, such as playing against dealers independently from other players, playing with tree
                  cards per hand.</p>

                <h3>MICROGAMING</h3>

                <h4>Slots</h4>

                <p>The goal of playing is to spin the reels until the combination of symbols on them matches the winning
                  kind. These combinations depend on particular gamedesign. The game starts with a betting process, bet
                  amount is adjustable. After the stake is made the starting button is to be pressed on the bottom of the
                  interface and the reels are spinning. In case of luck all the payouts are made according to the payout
                  table. The number of reels in slot game varies from 3 to 7 with the number of lines from 1 to 720 (4096
                  ways max). In some slots free games are available.</p>

                <h4>Blackjack</h4>

                <p>The game is played with 8 standard decks of 52 cards &ndash; cards are returned to the deck after each
                  hand and reshuffled. Number cards (2-10)count as face value. Face cards (kings, queens and jacks) are
                  equal in value to 10 and aces are valued at either 1 or 11. There is no special significance to any of
                  the 4 suits. The dealer is dealt two cards, and the player is dealt two cards in each box. The
                  player&rsquo;s cards are dealt face up, while the dealer&rsquo;s first card is shown.<br />
                  A player can choose an additional card by clicking Hit and repeat this as many times as he likes so long
                  as the total of his cards is less than 21.<br />
                  If the total surpasses 21, it is called a &lsquo;bust&rsquo; and the player automatically loses.<br />
                  Once a player is satisfied with the cards dealt, click Stand. After the player is finished, the dealer
                  reveals the face down card and decides whether to take another card. The dealer must have cards totaling
                  17 or more before standing.<br />
                  The dealer must stand on soft 17 (ie where total of dealer&rsquo;s cards is 17 and one card is an Ace).
                  If the value of the player&rsquo;s card is greater than the dealer&rsquo;s, or if the dealer busts then
                  the player is the winner. If the player&rsquo;s card total is the same as the dealer it is considered a
                  &lsquo;push&rsquo; and the player&rsquo;s bet is returned.<br />
                  After the end of the game, winnings are paid and the player has the choice to &lsquo;Rebet&rsquo; or
                  make a &lsquo;New bet&rsquo; which might include reducing or increasing the number of hands played and
                  the amount bet on each.<br />
                  Some game varieties offer multihand playing, or using another amount of decks and many other
                  possibilities.</p>

                <h4>Roulette</h4>

                <p>Roulette is a game played with a large wheel that contains either 37 or 38 pockets. These stops are
                  numbered from 0 to 36,sometimes 00 pockets are also present. All the pockets are all colored,the zeroes
                  are green, while the other spots are evenly divided between 18 red and 18 black pockets. The dealer
                  spins a ball on the outer rim of the wheel, after which it will eventually fall into one of the numbered
                  spaces. The object for the player is to guess what number the ball will land in.Before each spin,
                  players have the opportunity to place bets around the roulette table. Players can bet on individual
                  numbers or virtually any combination of numbers, there are several ways: for one number, two numbers,
                  three numbers, corner, six numbers, all black or red numbers, all even or odd numbers, dozens, columns,
                  1-18/19-36.</p>

                <h4>Poker</h4>

                <p>Poker is usually played with a deck of 52 cards, but it may vary. The goal of the game is to win the
                  bets by gathering the highest poker combination of cards. These combinations are ranked from the highest
                  to the lowest one in the following order: Royal Flush, Straight Flush, Four of a Kind, Full House,
                  Flush, Straight, Three of a Kind, Two Pairs, Pair, High card. If the combination is the same by players,
                  high cards should be compared. Each dealing session has several rounds. In order to participate in the
                  game, an &ldquo;ante&quot; bet should be placed (or &ldquo;blind&quot; in some variations). Players in a
                  poker game act in turn, in clockwise rotation. Each player may &ldquo;fold,&quot; which is to drop out
                  of the hand losing any bets they have already made or &ldquo;call,&quot; which is to match the highest
                  bet so far made. &ldquo;Raise,&quot; means to increase the previous high bet. Different game types offer
                  various options, such as &ldquo;double&quot; option or playing with wildcards, which can substitute for
                  all other cards.</p>

                <h3>MRSLOTTY</h3>

                <h4>Slots</h4>

                <p>The goal of playing is to spin the reels until the combination of symbols on them matches the winning
                  kind. These combinations depend on particular gamedesign. The game starts with a betting process, bet
                  amount is adjustable. After the stake is made the starting button is to be pressed on the bottom of the
                  interface and the reels are spinning. In case of luck all the payouts are made according to the payout
                  table. The number of winlines varies from 18 to 55.</p>

                <h3>NETENT</h3>

                <h4>Slots</h4>

                <p>The goal of playing is to spin the reels until the combination of symbols on them matches the winning
                  kind. These combinations depend on particular gamedesign. The game starts with a betting process, bet
                  amount is adjustable. After the stake is made the starting button is to be pressed on the bottom of the
                  interface and the reels are spinning. In case of luck all the payouts are made according to the payout
                  table. The majority of games have 5 reels with 3-4 rows on each. The number of paylines varies from 9 to
                  40. In order to maintain player&rsquo;s excitement, lots of them offer freespins and bonus games.</p>

                <h4>Baccarat</h4>

                <p>The object of Baccarat is to predict which hand will get a point value closest to 9. Scores range from
                  0 to 9, and the hand with the highest point value wins. To participate in the game, the bet is placed on
                  the banker&rsquo;s hand, on the player&rsquo;s hand, or on a tie. Then the player and the banker are
                  each dealt a two-card hand. Following standard baccarat rules, a third card is dealt to the player
                  and/or the banker. If the game does result in a tie, a wager for the banker or the player is
                  returned.However, if you win by betting on the banker, the bank receives 5% commission.If you correctly
                  wager that the two hands will tie, your payoff is 8 to1.<br />
                  * Ace = one point.<br />
                  * Face cards and tens = zero.<br />
                  * All other cards carry the point value stated on the card.<br />
                  If the cards in a hand total more than ten points, simply subtract ten; the remainder is the baccarat
                  point value of the hand. For example: 8+8=16, which counts as a 6 in baccarat; and 8+9=17, which counts
                  as 7. There are also varieties of this game with high and low limits.</p>

                <h4>Roulette</h4>

                <p>Roulette is a game played with a large wheel that contains either 37 or 38 pockets. These stops are
                  numbered from 0 to 36,sometimes 00 pockets are also present. All the pockets are all colored,the zeroes
                  are green, while the other spots are evenly divided between 18 red and 18 black pockets. The dealer
                  spins a ball on the outer rim of the wheel, after which it will eventually fall into one of the numbered
                  spaces. The object for the player is to guess what number the ball will land in. Before each spin,
                  players have the opportunity to place bets around the roulette table. Players can bet on individual
                  numbers or virtually any combination of numbers, there are several ways: for one number, two numbers,
                  three numbers, corner, six numbers, all black or red numbers, all even or odd numbers, dozens, columns,
                  1-18/19-36.The minimum and maximum bets for the table are displayed on the MIN/MAX section of the table.
                  Any bets placed that are below the minimum bet limit for the table are not valid and will not be
                  accepted by the dealer. Any bets placed that are above the maximum bet limit for the table are
                  automatically adjusted to match the maximum limit. Important to mention, that there are also varieties
                  offering high or low limits.</p>

                <h4>Blackjack</h4>

                <p>The game is played with 6 decks of cards and is hosted by a live dealer.The object of the game is to
                  create a hand with a value that is equal to or closer to 21 than the dealer&rsquo;s hand without going
                  over.The minimum and maximum bets for the table are displayed on the MIN/MAX section of the screen.Any
                  bets placed that are below the minimum bet limit for the table are not valid and will not be accepted by
                  the dealer. Any bets placed that are above the maximum bet limit for the table are automatically
                  adjusted to match the maximum limit. After the close of betting, the dealer deals one card face up to
                  themselves, and two cards face up into the card stream. The dealer&rsquo;s first card is not part of the
                  stream. Based on the value of the two cards dealt into the card stream and the dealer&rsquo;s card, each
                  player at the table will decide how to proceed with the hand according to Blackjack rules. If players
                  decide to hit, double, or split, the next card or cards dealt into the stream are counted toward those
                decisions. If players decide to stand, or their hand busts, cards may continue to be dealt into the card
                stream as other players at the table have not yet completed their hands. Once all players have concluded
                their hands, the dealer draws a card or cards to their own hand. The game round is finished when the
                dealer concludes their hand. The dealer then clears the table and all winning bets are paid. Number
                cards (2-10) count as face value. Face cards (kings, queens and jacks) are equal in value to 10 and aces
                are valued at either 1 or 11. There is no special significance to any of the 4 suits. Some game
                varieties offer high or low limit playing, or leaving dealer&rsquo;s cards down face (Pontoon).</p>

              <h4>Poker</h4>

              <p>Poker is usually played with a deck of 52 cards, but it may vary. The goal of the game is to win the
                bets by gathering the highest poker combination of cards. These combinations are ranked from the highest
                to the lowest one in the following order: Royal Flush, Straight Flush, Four of a Kind, Full House,
                Flush, Straight, Three of a Kind, Two Pairs, Pair, High card. If the combination is the same by players,
                high cards should be compared. Each dealing session has several rounds. In order to participate in the
                game, an &ldquo;ante&quot; bet should be placed (or &ldquo;blind&quot; in some variations). Players in a
                poker game act in turn, in clockwise rotation. Each player may &ldquo;fold,&quot; which is to drop out
                of the hand losing any bets they have already made or &ldquo;call,&quot; which is to match the highest
                bet so far made. &ldquo;Raise,&quot; means to increase the previous high bet. Different game types offer
                various options, such as playing with low or high limits.</p>

              <h4>Texas Holdem</h4>

              <p>The game is played with a standard 52 card deck. The goal is to gather the highest poker card
                combination:Royal Flush, Straight Flush, Four of a Kind, Full House, Flush, Straight, Three of a Kind,
                Two Pairs, Pair, High card. Two cards are dealt face down to each player, and then five community cards
                are dealt face up in three stages. The stages consist of a series of three cards (&ldquo;the
                flop&quot;), later an additional single card (&ldquo;the turn&quot;), and a final card (&ldquo;the
                river&quot;). Each player seeks the best five card poker hand from any combination of the seven cards of
                the five community cards and their own two hole cards. The &ldquo;ante&quot; bet can be raised only once
                a round by choosing &ldquo;Play&quot;. &ldquo;Check&quot; means the bet is to stay the same. Different
                game types offer various options, such as playing with low or high limits.<br />
                The kind of Texas Holdem is Caribbean Stud poker, where the game is played against the dealer.</p>

              <h4>Red Dog</h4>

              <p>The game starts with an ante bet. Once the bet is chosen, the DEAL button is clicked.One deck of 52
                cards is used. Two cards are turned up, with a space between them for another card. The game is simple:
                predict whether the next card will fall between the two shown. The bet is made on spread (the number of
                cards between those two turned up). Ace is 14 points, face cards are 13,12,11 accordingly, all other
                cards carry the point value stated on the card. If the player is confident that the next card will fall
                between the two shown, he chooses RAISE to double the bet. If the player is not confident, he chooses
                CALL to turn the third card. If a player&rsquo;s first two cards are consecutive or a pair, the game is
                a tie and he gets his money back. If the player&rsquo;s first two cards are a pair and the third makes a
                three of a kind, the player gets 11:1. All payouts are made according to the payout table.</p>

              <h3>TOMHORN</h3>

              <h4>Slots</h4>

              <p>The goal of playing is to spin the reels until the combination of symbols on them matches the winning
                kind. These combinations depend on particular gamedesign. The game starts with a betting process, bet
                amount is adjustable. After the stake is made the starting button is to be pressed on the bottom of the
                interface and the reels are spinning. In case of luck all the payouts are made according to the payout
                table. The number of winlines varies from 5 to 243.</p>

              <h4>Baccarat</h4>

              <p>Baccarat is played with a single standard deck of 52 cards. The objective of the game is to bet on the
                hand that you think will have the highest total value: the Banker&rsquo;s hand, the Player&rsquo;s hand,
                or that it will be a Tie. After the Players have placed their bet, the card dealer gives two cards to
                each, first to the Player and then to the Banker. Face cards and 10s have no value. Cards with a nominal
                value lower than 10 are counted at their nominal value. Aces are worth 1. Suits do not matter in this
                game. Only single digit nominal values are valid. Any count that reaches a double digit drops the left
                digit, e.g. 15 is counted as 5 and 25 is also counted as 5.<br />
                This poker game is played with three cards per hand, but unlike regular poker where players compete
                against each other, all players at this game compete either against the dealer or against a posted
                payout schedule for specific hands. It is usually played with a deck of 52 cards, but it may vary. The
                goal of the game is to win the bets by gathering the highest poker combination of cards. These
                combinations are ranked from the highest to the lowest one in the following order: Royal Flush, Straight
                Flush, Four of a Kind, Full House, Flush, Straight, Three of a Kind, Two Pairs, Pair, High card. If the
                combination is the same by players, high cards should be compared. Each dealing session has several
                rounds. In order to participate in the game, an &ldquo;ante&quot; bet should be placed. Players in a
                poker game act in turn, in clockwise rotation. Each player may &ldquo;call&quot; which is to continue
                playing by doubling &ldquo;ante&quot; bet. Additionally this game allows the player to make a
                facultative bet &ldquo;Pair plus&quot; which wins if a player gets Pair or another higher card
                combination. It is usually paid out independently from the main game result.</p>

              <h4>Roulette</h4>

              <p>Roulette is a game played with a large wheel that contains either 37 or 38 pockets. These stops are
                numbered from 0 to 36,sometimes 00 pockets are also present. All the pockets are all colored,the zeroes
                are green, while the other spots are evenly divided between 18 red and 18 black pockets. The dealer
                spins a ball on the outer rim of the wheel, after which it will eventually fall into one of the numbered
                spaces. The object for the player is to guess what number the ball will land in. Before each spin,
                players have the opportunity to place bets around the roulette table. Players can bet on individual
                numbers or virtually any combination of numbers, there are several ways: all black or red numbers, all
                even or odd numbers, dozens, columns, 1-18/19-36.</p>

              <ul>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div></template>

<style scoped lang="scss">.windowInfo {
  border-radius: 10px;
  background: #112a34;
  margin-top: 40px;
}

.areaNavigate {
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
  border-right: 1px solid rgb(255, 255, 255);
  height: 100%;
  width: 100%;
}

@media (max-width: 1190px) {
  .areaNavigate {
    display: none;
  }
}

.field__space {
  padding: 15px 10px 15px 25px;
  border-bottom: 1px solid rgb(255, 255, 255);
}

.areaNavigate a:last-of-type .field__space {
  border-bottom: none;
}


.field__space:hover {
  background-color: #d4145a;
  transition: all 0.4s linear;
}

// Field

.textArea {
  padding: 40px;
  text-align: left;
  font-size: 1.6rem;


  &__title {
    font-size: 3rem;
    padding-bottom: 20px;
  }

  &__discord {
    padding-bottom: 20px;

    display: flex;
    align-items: center;

    &__time {
      padding-right: 18px;
      font-weight: 800;
    }
  }

  &__contact {
    display: flex;

    &__mail {

      &__fieldMail {
        color: #667b83;
        font-size: 1.2rem;
        align-items: center;
      }

      &__adresMail {
        color: #d4145a;
        font-weight: 600;
      }
    }

    &__anserTime {

      padding-left: 50px;

      &__field {
        color: #667b83;
        font-size: 1.2rem;
        align-items: center;
      }

      &__timeAnser {
        color: #d4145a;
        font-weight: 600;
      }
    }
  }

  &__fieldLine {
    height: 2px;
    margin: 15px 0 20px 0;
    background: rgb(255, 255, 253, 0.5);
    opacity: 1;

  }

  &__name {
    color: #667b83;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }

  &__inputName {
    margin-bottom: 25px;
  }


  &__mail {
    color: #667b83;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }

  &__inputMail {
    margin-bottom: 25px;
  }

  &__theme {
    color: #667b83;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }

  &__inputTheme {
    margin-bottom: 25px;
  }

  &__mesg {
    color: #667b83;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }

  &__inputMesg {
    margin-bottom: 25px;
  }

}

.input__style {
  appearance: none;
  color: #000;
  display: block;
  width: 100%;
  height: 40px;
  padding: 10px 40px 10px 10px;
  outline: none;
  border: 2px solid #667b83;
  border-radius: 10px;
}

.styleButton {
  color: aliceblue;
  background: #d4145a;
  border: 2px solid #d4145a;
  border-radius: 3px;
  padding: 5px 20px;
}

.styleButton:hover {
  color: aliceblue;
  background: #d4145a;
  box-shadow: 0 0 15px #ff3d84;
  transition: all 0.4s linear;
}

.massange {
  min-height: 230px;
}

.active {
  background-color: #d4145a;
}

a {
  color: inherit;
  text-decoration: none;
  border: none;
}
</style>