<template>
  <div class="home">
    <HeaderSectionNEW />
    <SideBarNew/>
    <SideBarBotMobile/>
    <TournamentArea/>
    <FooterSection />
  </div>
</template>


<script>
import HeaderSectionNEW from "@/components/HeaderSectionNEW.vue";
import SideBarBotMobile from "@/components/SideBarBotMobile.vue"
import SideBarNew from "@/components/SideBarNew.vue";
import TournamentArea from "@/components/TournamentPage/TournamentArea.vue";
import FooterSection from "@/components/FooterSection.vue";
import 'animate.css';
import "@fontsource/inter";



export default {
  name: "TournamentsPage",
  components: {
    HeaderSectionNEW,
    SideBarBotMobile,
    SideBarNew,
    TournamentArea,
    
    FooterSection,
    
    
  },
};
</script>