<!-- eslint-disable prettier/prettier -->
<template>
  <div class="container">
    <div class="loyalty">
      <div class="row">
        <div class="col-6 col-md-6 col-xl-3 space1">
          <div class="loyalty__card">
            <div class="loyalty__card-icon">
              <img
                class="loyalty__card-icon__img"
                src="@/assets/img/loyalty/lolyalty.png"
                alt=""
              />
            </div>
            <div class="loyalty__card-title">1 Уровень</div>
            <div class="loyalty__card-quantity">
              0 - 100
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.7672 5.59082L7.71655 14.3839L15.2166 5.59082H10.7672Z"
                  fill="#FFE182"
                ></path>
                <path
                  d="M3.32 1.71143L0.216553 5.59074H4.6659L3.32 1.71143Z"
                  fill="#FFCD73"
                ></path>
                <path
                  d="M10.7671 5.59074H15.2164L12.113 1.71143L10.7671 5.59074Z"
                  fill="#FFCD73"
                ></path>
                <path
                  d="M10.7673 5.59074L7.71667 1.71143L4.66602 5.59074H10.7673Z"
                  fill="#FFCD73"
                ></path>
                <path
                  d="M7.71662 1.71143H3.32007L4.66597 5.59074L7.71662 1.71143Z"
                  fill="#FFAA64"
                ></path>
                <path
                  d="M12.1131 1.71143H7.71655L10.7672 5.59074L12.1131 1.71143Z"
                  fill="#FFE182"
                ></path>
                <path
                  d="M4.66602 5.59082L7.71667 14.3839L10.7673 5.59082H4.66602Z"
                  fill="#FFAA64"
                ></path>
                <path
                  d="M0.216553 5.59082L7.71655 14.3839L4.6659 5.59082H0.216553Z"
                  fill="#FF8C5A"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6 col-xl-3 space1">
          <div class="loyalty__card">
            <div class="loyalty__card-icon">
              <img
                class="loyalty__card-icon__img"
                src="@/assets/img/loyalty/lolyalty.png"
                alt=""
              />
            </div>
            <div class="loyalty__card-title">2 Уровень</div>
            <div class="loyalty__card-quantity">
              100 - 500
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.7672 5.59082L7.71655 14.3839L15.2166 5.59082H10.7672Z"
                  fill="#FFE182"
                ></path>
                <path
                  d="M3.32 1.71143L0.216553 5.59074H4.6659L3.32 1.71143Z"
                  fill="#FFCD73"
                ></path>
                <path
                  d="M10.7671 5.59074H15.2164L12.113 1.71143L10.7671 5.59074Z"
                  fill="#FFCD73"
                ></path>
                <path
                  d="M10.7673 5.59074L7.71667 1.71143L4.66602 5.59074H10.7673Z"
                  fill="#FFCD73"
                ></path>
                <path
                  d="M7.71662 1.71143H3.32007L4.66597 5.59074L7.71662 1.71143Z"
                  fill="#FFAA64"
                ></path>
                <path
                  d="M12.1131 1.71143H7.71655L10.7672 5.59074L12.1131 1.71143Z"
                  fill="#FFE182"
                ></path>
                <path
                  d="M4.66602 5.59082L7.71667 14.3839L10.7673 5.59082H4.66602Z"
                  fill="#FFAA64"
                ></path>
                <path
                  d="M0.216553 5.59082L7.71655 14.3839L4.6659 5.59082H0.216553Z"
                  fill="#FF8C5A"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6 col-xl-3 space1">
          <div class="loyalty__card">
            <div class="loyalty__card-icon">
              <img
                class="loyalty__card-icon__img"
                src="@/assets/img/loyalty/lolyalty.png"
                alt=""
              />
            </div>
            <div class="loyalty__card-title">3 Уровень</div>
            <div class="loyalty__card-quantity">
              500 - 3000
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.7672 5.59082L7.71655 14.3839L15.2166 5.59082H10.7672Z"
                  fill="#FFE182"
                ></path>
                <path
                  d="M3.32 1.71143L0.216553 5.59074H4.6659L3.32 1.71143Z"
                  fill="#FFCD73"
                ></path>
                <path
                  d="M10.7671 5.59074H15.2164L12.113 1.71143L10.7671 5.59074Z"
                  fill="#FFCD73"
                ></path>
                <path
                  d="M10.7673 5.59074L7.71667 1.71143L4.66602 5.59074H10.7673Z"
                  fill="#FFCD73"
                ></path>
                <path
                  d="M7.71662 1.71143H3.32007L4.66597 5.59074L7.71662 1.71143Z"
                  fill="#FFAA64"
                ></path>
                <path
                  d="M12.1131 1.71143H7.71655L10.7672 5.59074L12.1131 1.71143Z"
                  fill="#FFE182"
                ></path>
                <path
                  d="M4.66602 5.59082L7.71667 14.3839L10.7673 5.59082H4.66602Z"
                  fill="#FFAA64"
                ></path>
                <path
                  d="M0.216553 5.59082L7.71655 14.3839L4.6659 5.59082H0.216553Z"
                  fill="#FF8C5A"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6 col-xl-3 space1">
          <div class="loyalty__card">
            <div class="loyalty__card-icon">
              <img
                class="loyalty__card-icon__img"
                src="@/assets/img/loyalty/lolyalty.png"
                alt=""
              />
            </div>
            <div class="loyalty__card-title">4 Уровень</div>
            <div class="loyalty__card-quantity">
              3000 - 50000
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.7672 5.59082L7.71655 14.3839L15.2166 5.59082H10.7672Z"
                  fill="#FFE182"
                ></path>
                <path
                  d="M3.32 1.71143L0.216553 5.59074H4.6659L3.32 1.71143Z"
                  fill="#FFCD73"
                ></path>
                <path
                  d="M10.7671 5.59074H15.2164L12.113 1.71143L10.7671 5.59074Z"
                  fill="#FFCD73"
                ></path>
                <path
                  d="M10.7673 5.59074L7.71667 1.71143L4.66602 5.59074H10.7673Z"
                  fill="#FFCD73"
                ></path>
                <path
                  d="M7.71662 1.71143H3.32007L4.66597 5.59074L7.71662 1.71143Z"
                  fill="#FFAA64"
                ></path>
                <path
                  d="M12.1131 1.71143H7.71655L10.7672 5.59074L12.1131 1.71143Z"
                  fill="#FFE182"
                ></path>
                <path
                  d="M4.66602 5.59082L7.71667 14.3839L10.7673 5.59082H4.66602Z"
                  fill="#FFAA64"
                ></path>
                <path
                  d="M0.216553 5.59082L7.71655 14.3839L4.6659 5.59082H0.216553Z"
                  fill="#FF8C5A"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <!-- Button trigger modal -->
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Look
            <img
              class="imgStyle"
              src="@/assets/img/newMainPage/arrow.svg"
              alt=""
            />
          </button>
          <!-- Modal -->
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <!-- <h5 class="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5> -->
                  <img
                    class="modal-header-wrapper"
                    src="@/assets/img/loyalty/loyalty-info-bg.jpg"
                    alt=""
                  />
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="container">
                    <div class="row">
                      <div class="col-xl-10 mx-auto">
                        <table class="table table-borderless">
                          <h3 class="modal-body-title">Программа лояльности</h3>
                          <!-- <div class="table__head">
                            <div class="table-row text-start">
                              <div class="table__head-cell">Уровень</div>
                              <div class="table__head-cell">Название уровня</div>
                              <div class="table__head-cell">Опыт</div>
                              <div class="table__head-cell">Коэффициент</div>
                            </div>
                          </div> -->
                          <!-- <div class="table__body">
                            <div class="table-row">
                              <div class="table__body-cell">1</div>
                              <div class="table__body-cell">Начинающий</div>
                              <div class="table__body-cell">100</div>
                              <div class="table__body-cell">0.5</div>
                            </div>
                            <div class="table-row">
                              <div class="table__body-cell">2</div>
                              <div class="table__body-cell">Любитель</div>
                              <div class="table__body-cell">500</div>
                              <div class="table__body-cell">1</div>
                            </div>
                            <div class="table-row">
                              <div class="table__body-cell">3</div>
                              <div class="table__body-cell">Игрок</div>
                              <div class="table__body-cell">3000</div>
                              <div class="table__body-cell">1.5</div>
                            </div>
                            <div class="table-row">
                              <div class="table__body-cell">4</div>
                              <div class="table__body-cell">Профессионал</div>
                              <div class="table__body-cell">50000</div>
                              <div class="table__body-cell">2</div>
                            </div>
                          </div> -->
                          <thead class="text-white">
                            <tr class="table-row-head">
                              <th scope="col">Уровень</th>
                              <th scope="col">Название уровня</th>
                              <th scope="col">Опыт</th>
                              <th scope="col">Коэффициент</th>
                            </tr>
                          </thead>
                          <tbody class="text-white">
                            <tr class="table-row two">
                              <th scope="row">1</th>
                              <td>Любитель</td>
                              <td>100</td>
                              <td>2</td>
                            </tr>
                            <tr class="table-row">
                              <th scope="row">2</th>
                              <td>Начинающий</td>
                              <td>300</td>
                              <td>2</td>
                            </tr>
                            <tr class="table-row two">
                              <th scope="row">2</th>
                              <td>Профи</td>
                              <td>5000</td>
                              <td>0,5</td>
                            </tr>
                            <tr class="table-row">
                              <th scope="row">2</th>
                              <td>Игрок</td>
                              <td>7000</td>
                              <td>1</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary btn-primary mx-auto"
                          data-bs-dismiss="modal"
                        >
                          Закрыть
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoyaltySection",
};
</script>
<style scoped lang="scss">
.space1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.loyalty {
  background-color: #131420;
  padding: 32px 16px 32px 16px;
  margin-top: 105px;
  border-radius: 19px;
  border: 2px dashed #7647dc;

  &__card {
    &-icon {
      background: #7647dc;
      border-radius: 50%;
      width: 40%;
      height: 40%;
      margin: auto;
      &__img {
        padding: 20px;
        width: 100%;
      }
    }

    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 1.45;
      margin: 26px 0 0 0;
    }

    &-quantity {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      line-height: 1.45;

      svg {
        margin-left: 5px;
      }
    }
  }

  .modal-header-wrapper {
    width: 100%;
    border-radius: 4px 4px 0 0;
  }

  .modal-header {
    padding: 0;
    position: relative;
  }

  .modal-content {
    background-color: #1B1C2C;
  }

  .modal-body-title {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.45;
    padding: 20px 0 20px 0;
  }

  // .table__head {
  //   display: flex;
  // }

  .table-row-head {
    font-size: 1.4rem;
    font-style: normal;
    line-height: 3;
    padding: 20px 10px;
  }

  .table-row {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    font-size: 1.4rem;
    font-style: normal;
    line-height: 3;
    // padding: 20px 10px;
  }

  .table-row:nth-child(2n + 1) {
    background-color: #1f3842;
    border-radius: 20px;
  }

  // .two {
  //   background-color: #1f3842;
  //   border-radius: 10px;
  // }

  // .table__body-cell{
  //   padding: 20px 10px;
  // }
  .btn-close {
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

@media (max-width: 650px) {
  .loyalty {
    margin: 50px 12px; 
  }

  .loyalty__card-icon{
    width: 60%;  
  }
}
.btn-primary {
  margin-top: 20px;

  background-color: #7647dc;
  border: 2px solid #7647dc !important;

  font-size: 14px;
  font-weight: 500;
  height: 50px;
  width: 100px;
  border-radius: 19px;
}

.btn-primary:hover {
  box-shadow: 0px 3px 20px 0px #7647dc;
}

.imgStyle {
  padding-left: 12px;
  padding-top: 10%;
}
</style>
