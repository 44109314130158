<template>
  <div class="home">
    <HeaderSectionNEW />
    <SideBarNew/>
    <SideBarBotMobile/>
    <ResponsibleGamblingArea/>
    <FooterSection />
  </div>
</template>

<script>
import HeaderSectionNEW from "@/components/HeaderSectionNEW.vue";
import SideBarBotMobile from "@/components/SideBarBotMobile.vue"
import SideBarNew from "@/components/SideBarNew.vue";
import ResponsibleGamblingArea from "@/components/ContactsUs/contacts/ResponsibleGamblingArea.vue"
import FooterSection from "@/components/FooterSection.vue";
import 'animate.css';




export default {
  name: "ContactUs",
  components: {
    HeaderSectionNEW,
    SideBarBotMobile,
    SideBarNew,
    FooterSection,
    ResponsibleGamblingArea
},
};
</script>