<template>
  <div class="infoArea">
    <div class="container text-light">
      <div class="windowInfo">
        <div class="row">
          <div class="col-xl-4">
            <div class="areaNavigate ">

              <a href="/contacts/contactus">
                <div class="contactUS field__space a-noDeciration">Связатся с нами</div>
              </a>

              <!-- <div class="blog field__space">Блог</div> -->

              <a href="/contacts/politics">
                <div class="politicsConf field__space a-noDeciration">Политикой Конфиденциальности</div>
              </a>

              <a href="/contacts/respons">
                <div class="gameRespons field__space a-noDeciration">Responsible Gambling</div>
              </a>
              <a href="/contacts/fairgames">
                <div class="rulesGame field__space a-noDeciration">Честная игра</div>
              </a>
              <a href="/contacts/rules">
                <div class="contactUS field__space a-noDeciration">Правила игр</div>
              </a>
              <a href="/contacts/terms">
                <div class="termsCondit field__space active a-noDeciration">Условиями и положениями</div>
              </a>

            </div>
          </div>
          <div class="col-xl-8 col-l2">
            <div class="textArea">
              <h1>Условиями и положениями</h1>

              <p>&nbsp;</p>

              <p>TERMS AND CONDITIONS (&ldquo;T&amp;Cs&quot;) FOR ROBET LIMITED: IMPORTANT &ndash; PLEASE READ THESE
                T&amp;C&rsquo;S CAREFULLY BEFORE PROCEEDING TO USE OUR SERVICES, AND/OR DOWNLOADING OUR SOFTWARE, AS YOUR
                AGREEMENT TO THEM CONSTITUTES A LEGALLY BINDING AGREEMENT. IT IS YOUR SOLE RESPONSIBILITY TO PERIODICALLY
                REVIEW THESE T&amp;Cs AND YOUR CONTINUED USE OF THE SITE, AND ASSOCIATED SERVICES, CONSTITUTES YOUR
                ONGOING ACCEPTANCE OF THE T&amp;Cs CONTAINED HEREINAFTER.</p>

              <p>These T&amp;Cs are between &ldquo;You&quot; (&ldquo;Your&quot; also refers to you throughout these
                T&amp;Cs) and RoBet Limited (&ldquo;RBL&quot;) of First Floor, Millennium House, Victoria Road, Douglas,
                Isle of Man, IM2 4RW, a company registered in the Isle of Man under company number 016919V.</p>

              <p>These Terms and Conditions exist under the jurisdiction governing law of the Isle of Man Online Gambling
                Regulation Act 2001.</p>

              <p><strong>A. INTRODUCTION</strong></p>

              <p>1. By using, visiting and/or accessing any part (including, but not limited to, sub-domains, source code
                and/or website APIs, whether visible or not) of the&nbsp;<u>coingames.fun</u>&nbsp;website or mobile
                application or any other websites or applications that we own or operate (the &ldquo;website&quot;) and/or
                registering on the website, you enter into a contract with RBL and agree to be bound by (i)
                these&nbsp;T&amp;Cs; (ii) our&nbsp;<u>Privacy Policy</u>; (iii) our&nbsp;<u>Cookies Policy</u>&nbsp;and
                (iv) the&nbsp;<u>Game&nbsp;</u><u>Rules</u>&nbsp;applicable to our betting, and are deemed to have
                accepted and understood the content of these.</p>

              <p>Please read the T&amp;Cs carefully and if you do not accept the T&amp;Cs, do not use, visit, or access
                any part (including, but not limited to, sub-domains, source code and/or website APIs, whether visible or
                not) of the website.</p>

              <p>2. Where you play any game or place a bet or wager using the website, you accept and agree to be bound by
                the rules which apply to the applicable products available on the website from time to time. The rules can
                be found under the &ldquo;Help&quot; tab of the applicable section of the website.</p>

              <p>3. Reference to &ldquo;we&quot;, &ldquo;our&rdquo; or &ldquo;us&quot; means &ldquo;RBL&quot;.</p>

              <p>4. We may need to change the T&amp;Cs from time to time for several reasons (including to comply with
                applicable laws and regulations, and regulatory requirements). All changes will be published on the
                website. The most up to date T&amp;Cs will be available on the website. When a material change is carried
                out on the T&amp;Cs the customer will be requested to accept the new T&amp;Cs before proceeding. Once
                accepted, the new T&amp;Cs will come into effect thereon. Should you, due to any possible changes, not
                wish to continue using the services of RBL, you can withdraw all of your available funds and close the
                account.</p>

              <p>5. Reference to &ldquo;you&rdquo;, &ldquo;your&rdquo; or &ldquo;customer&rdquo; is reference to any
                person using the website or the services of RBL and/or any registered customer of RBL.</p>

              <p>6. As you are aware, the right to access and/or use the website (including any or all of the products
                offered via the website) may be illegal in certain countries. You are responsible for determining whether
                your access and/or use of the website is compliant with applicable laws in your jurisdiction and you
                warrant to us that gambling is not illegal in the territory where you reside.</p>

              <p>7. RBL is committed to providing excellent customer service. As part of that commitment, RBL is committed
                to supporting responsible gambling, for further details please click&nbsp;<a href="#">here</a>.</p>

              <p>Although RBL will use its reasonable endeavours to enforce its responsible gambling policies, RBL does
                not accept any responsibility or liability if you nevertheless continue gambling and/or seek to use the
                website with the intention of deliberately avoiding the relevant measures in place and/or RBL is unable to
                enforce its measures/policies for reasons outside of RBL&rsquo;s reasonable control.&nbsp;</p>

              <p><strong>B. YOUR RBL ACCOUNT</strong></p>

              <p><strong>1. Application</strong></p>

              <p>1.1 All applicants must be over 18 years of age and of legal capacity to place a bet/wager or register
                with RBL. RBL reserves the right to ask for proof of age from any customer and to suspend their account
                until satisfactory documentation is provided. RBL takes its responsibilities in respect of underage and
                responsible gambling very seriously. RBL reserves the right to refuse any application for registration
                without giving a reason for refusal.</p>

              <p>1.2 All customers must register personally and information supplied when registering with the website
                (including without limitation full name, date of birth, address, contact email and personal telephone
                number). The information provided must be true, accurate and complete in all respects. Where this is not
                the case, we reserve the right to suspend the account and treat any deposits into the gambling account as
                being invalid (and any winnings arising from such deposit as void).</p>

              <p>1.3 By accepting the&nbsp;T&amp;Cs&nbsp;and/or registering to use the website you hereby agree that we
                shall be entitled to conduct any and all such identification, credit and other verification checks from
                time to time that we may require and/or are required by applicable laws and regulations (including without
                limitation for the purpose of complying with anti-money laundering laws and regulations) and/or by the
                relevant regulatory authorities for use of the website and our products generally. You agree to provide
                all such information as we require in connection with such verification checks. We shall be entitled to
                suspend or restrict your account in any manner that we may deem to be appropriate, until such time as the
                relevant checks are completed to our satisfaction.</p>

              <p>1.4 When&nbsp;required we need to verify the identity of our players and therefore reserve the right to
                request satisfactory proof of your identity (including but not limited to copies of a valid
                passport/driving license/identity card and/or any payment cards used) and satisfactory proof of address
                (including but not limited to a recent bank/credit card statement or utility bill dated within the last 6
                months) at any time. Failure to supply such documentation within a period of 3 weeks after initial
                registration may result in the suspension of services and ultimately the closure of your account.</p>

              <p>With RBL, player registration and verification is a prerequisite for deposits and payouts. RBL has set a
                monthly limit of &euro; 3,000 before full due diligence is conducted on the player. The following are the
                current three steps that RBL requires all users to take in order to activate their account:</p>

              <p>&nbsp;</p>

              <ul>
                <li>A valid passport or both sides of the ID card to verify the player&rsquo;s age.</li>
              </ul>

              <ul>
                <li>A selfie with the above-mentioned Passport and/or ID, as well as a piece of paper with the statement
                  &ldquo;Hi, casino_name, current date and registration e-mail.&quot;</li>
              </ul>

              <ul>
                <li>A proof-of-address document (can be a utility bill, a bank statement or another official government
                  document that is dated within the last 6 months)</li>
              </ul>

              <p>&nbsp;</p>

              <p>&nbsp;</p>

              <p>While the KYC process is ongoing, the player will not be able to play in the casino.</p>

              <p>In adherence with the Online Gambling (Registrations and Accounts) Regulation 2008 &ndash; after clicking
                on the &ldquo;Sign Up&quot; or &ldquo;Create New Account&quot; links, players are required to provide the
                following information:</p>

              <p>&nbsp;</p>

              <ul>
                <li>Email Address</li>
              </ul>

              <ul>
                <li>Username</li>
              </ul>

              <ul>
                <li>Nationality</li>
              </ul>

              <p>&nbsp;</p>

              <p>Players will be required to confirm that they have read and agreed to the Terms and Conditions of the
                site and also to confirm that they are aged 18 or over.</p>

              <p>1.5 As part of the registration process, we may supply your information details to authorised credit
                reference agencies to confirm your identity and payment card details. You agree that we may process such
                information in connection with your registration.</p>

              <p>1.6 Customers may open only one account. Should we identify any customer with more than one account we
                reserve the right to treat any such accounts as one joint account.&nbsp;Should we identify any duplicate
                accounts, these will be closed immediately and funds forfeited.</p>

              <p>1.7 Customers must keep their registration and account details up to date. This, and your account
                information, may be amended in the &lsquo;personal&rsquo; section of the website.</p>

              <p>1.8 RBL reserves the right to refuse to register any customer account application at its sole discretion.
              </p>

              <p>1.9 In order to open an account with RBL you must visit&nbsp;<a href="#">coingamesfun</a>&nbsp;and click
                on the &lsquo;Register&rsquo; tab at the top
                to screen, then follow the registration instructions.</p>

              <p><strong>2. Account Details</strong></p>

              <p>2.1 RBL allows all its customers to choose their own Username and Password combination for their account.
                Customers must keep this information secret and confidential as you are responsible for all bets/wagers
                placed on your account and any other activities taking place on your account.&nbsp;</p>

              <p>2.2 Bets will stand if your Username and Password have been entered correctly or if your account has been
                accessed via Metamask, Touch ID, Fingerprint log in, Face ID, Passcode or the &lsquo;Keep me logged
                in&rsquo; facility (whether or not authorised by you), subject to there being sufficient funds.</p>

              <p>2.3 If, at any time, you feel a third party is aware of your Username, Password and/or Passcode you
                should change it immediately via the website.</p>

              <p>2.4 If you activate Metamask, Touch ID, Fingerprint log in, Face ID, Passcode and/or the &lsquo;Keep me
                logged in&rsquo; facility and feel that a third party could be accessing your account via any of these
                methods then you should immediately disable Touch ID, Fingerprint log in, Face ID, Passcode and/or the
                &lsquo;Keep me logged in&rsquo; facility from all your devices. You are responsible for the security of
                your device(s) and for all bets/wagers placed on your account and any other activities taking place on
                your account.</p>

              <p>2.5 Please note that cardholder details and any other sensitive data should never be sent to us as we do
                not currently accept fiat payments.</p>

              <p>2.6 The current balance and transaction history of your account may be viewed at any time once you have
                logged into your account on the website.&nbsp;</p>

              <p><strong>3. Personal Details</strong></p>

              <p>3.1 RBL has notified the Isle of Man Information Commissioner that it is processing personal data in
                accordance with the General Data Protection Regulations.</p>

              <p>3.2 RBL will only process your personal data for the purposes for which it collected it, namely to
                provide you with an online gaming service. Such information includes your name, address, date of birth,
                email address and telephone number. Your information will only be seen or used by those who need to access
                your data to provide you with a service.</p>

              <p>3.3 RBL does not, unless you opt to allow us to do so, share your data with any other third party.
                However, at times we may be required by law or legal process to disclose your personal information.</p>

              <p>3.4 You have a right to access your data and request it to be changed or deleted.</p>

              <p>3.5 For further information about how RBL processes your data please view our Privacy Policy&nbsp;<a
                  href="#">here</a>.</p>

              <p><strong>4. Suspension and Closure</strong></p>

              <p>4.1 If you want to close your account, please contact us.</p>

              <p>4.2 RBL reserves the right to close or suspend your account at any time and for any reason without
                limiting the preceding sentence, RBL shall be entitled to close or suspend your account if:</p>

              <p>(a) you become bankrupt;</p>

              <p>(b) RBL considers that you have used the website in a fraudulent manner or for illegal and/or unlawful or
                improper purposes;</p>

              <p>(c) RBL considers that you have used the website in an unfair manner, have deliberately cheated or taken
                unfair advantage of RBL or any of its customers or if your account is being used for the benefit of a
                third party;</p>

              <p>(d) RBL is requested to do so by the police, any regulatory authority or court;</p>

              <p>(e) RBL considers that any of the events referred to in (a) to (c) above may have occurred or are likely
                to occur; or</p>

              <p>(f) your account is deemed to be dormant or is otherwise closed in accordance with paragraph B.5.1 below.
              </p>

              <p>4.3 If RBL closes or suspends your account for&nbsp;any of the reasons referred to in (a) to (e) above,
                you shall be liable for any and all claims, losses, liabilities, damages, costs, and expenses incurred or
                suffered by RBL (together &ldquo;claims&quot;) arising therefrom and shall indemnify and hold RBL harmless
                on demand for such claims. In the circumstances referred to in (a) to (e) above, RBL shall also be
                entitled to withhold and/or retain any and all amounts which would otherwise have been paid or payable to
                you (including any winnings, bet credits, or bonus payments).</p>

              <p>4.4&nbsp;RBL also reserves the right to immediately terminate or suspend your account should the user
                abuse any of our staff or make (and threaten to make) any defamatory or false statement about any company
                within the RoBET or if you commit any breach of obligated confidentiality owed by you to any company
                within the RoBET. In the event of a breach of the T&amp;C between the user and RoBET in the aforementioned
                manners, RBL is additionally entitled (but not obliged) to suspend any funds the user may deposit (or
                already has deposited) in any Additional Group Account.</p>

              <p><strong>5. Dormant Accounts</strong></p>

              <p>5.1 RBL will will close accounts that have been &lsquo;inactive&rsquo; for a consecutive period of at
                least 365 days in accordance with the following procedure. An account is deemed to be
                &lsquo;inactive&rsquo; during any period in which none of the following actions take place in relation to
                it: (i) a successful deposit; (ii) any gambling activities;</p>

              <p>(a) If your account remains &lsquo;inactive&rsquo; for a continuous period of 365 days then your account
                shall be deemed to be &lsquo;dormant&rsquo; and, if the balance on your account is zero, your account will
                be closed and no fee shall apply.</p>

              <p>(b) If, on being deemed to be dormant, your account has a positive balance, RBL shall take reasonable
                steps to notify you using the details you provided during your registration process (or as updated by
                you).</p>

              <p>(c) If your account remains dormant, after a minimum period of 28 days following RBL&rsquo;s first
                attempt to notify you that your account has become dormant, RBL shall deduct an administration fee from
                your account balance of &pound;2 (or currency equivalent), or 5% of your account balance at the time that
                it is deemed to be dormant (whichever is greater).</p>

              <p>(d) The administration fee calculated in accordance with (c) above shall be deducted from your account
                balance on the expiry of the 28 day notification period mentioned above and every 28 days thereafter at
                the same rate until the earlier of: (i) your account balance reaching zero, when no further administration
                fee shall be deducted and your account will be closed; or (ii) you &lsquo;reactivating&rsquo; your account
                when no further administration fee shall be deducted.</p>

              <p>5.2 You can &lsquo;reactivate&rsquo; your account by: (i) making a successful deposit; (ii) gambling.</p>

              <p>5.3 You can request a refund of any administration fees by contacting our Customer Service department
                which may be considered if an account is reactivated.</p>

              <p><strong>6. Errors</strong></p>

              <p>As a regulated business RBL is committed to fair play. Whilst rare, should an erroneous or palpable error
                occur in any calculations with respect to any stake you have placed , RBL will seek to put all parties
                into the position they would have been in, but for the occurrence of the error. In rectifying any error,
                it may be necessary to declare a stake null and void. If the error results in you being unfairly enriched,
                RBL reserve the right to reduce your account balance by the amount paid in error or, if the funds are
                withdrawn, to make demand of you to immediately reimburse RBL.</p>

              <p><strong>C. DEPOSITS AND WITHDRAWALS</strong></p>

              <p>Only Metamask users can deposit/withdraw funds from the vault.</p>

              <p>To make a BNB available deposit, the user should select the &ldquo;Wallets&quot; section in the profile
                menu on the website, connect the Metamask wallet and chose the amount that want to convert in DEGA.</p>

              <p>To make a&nbsp;<strong>withdrawal</strong>, while in the &ldquo;wallet&rdquo; tab, the user should select
                &ldquo;Withdrawal&quot;, connect a Metamask wallet and the transfer amount then confirm the transaction to
                execute.</p>

              <p><strong>D. RESPONSIBLE GAMBLING</strong></p>

              <p>RBL offers to player several means to set up self-protection mechanisms. The customer can set up:</p>

              <p>&ndash; A limit on daily, weekly and monthly maximum deposits.</p>

              <p>&ndash; A limit on daily, weekly and monthly bet limits.</p>

              <p>&ndash;&nbsp;A limit on daily, weekly and monthly&nbsp;losses&nbsp;limits.</p>

              <p>&ndash; A self-exclusion for a definite or indefinite period.</p>

              <p>A player who has set a limit or exclusion under this regulation may change or revoke the limit or
                exclusion by written notice or electronic notice given to the licensee.</p>

              <p>A notice increasing or revoking a limit or decreasing the exclusion has effect only after seven days
                after the licensee has received the notice.</p>

              <p>User can choose to self-exclude themselves and/or set the limits by contacting our&nbsp;dedicated team
                at&nbsp;<a href="#">support@coingames.bet</a>, where their request will
                be&nbsp;immediately complied with.</p>

              <p>RBL also informs users that any permanently excluded player (whether excluded by RBL for a serious breach
                of terms (See points 4.3 and 4.4) or by their own written notice to the casino)&nbsp; cannot undo the
                exclusion and is unable to re-register with RBL.</p>

              <p>A notice reducing a limit or increasing the exclusion has effect immediately after it is received by RBL.
              </p>

              <p>Set the maximum loss limit for a selected period and once set may&nbsp;be amended according to the
                following timescales:<br />
                &ndash; Changing the loss limit to be more restrictive takes effect immediately.<br />
                &ndash; Changing the loss limit to be less restrictive takes effect 48 hours after the change.</p>

              <p><strong>E. OUR LIABILITY</strong></p>

              <p>1. RBL does not accept any liability for any damages, liabilities or losses which are deemed or alleged
                to have arisen out of or in connection with the website or its content (including delays or interruptions
                in operation or transmission, loss or corruption of data, communication or lines failure, any
                person&rsquo;s misuse of the website or its content or any errors or omissions in content).&nbsp;</p>

              <p>2. While RBL endeavours to ensure that the information on the website is correct, RBL does not guarantee
                the accuracy or completeness of the information and material on the website. The website may contain
                typographical errors or other inaccuracies, or information that is out of date. RBL is under no obligation
                to update such material. The information and material on the website is provided &ldquo;as is&quot;,
                without any conditions, warranties, or other terms of any kind. Accordingly, to the maximum extent
                permitted by law, RBL provides you with the website on the basis that RBL excludes all representations,
                express or implied warranties, conditions, and other terms which but for these T&amp;Cs might have effect
                in relation to the website.</p>

              <p>3. RBL&rsquo;s total aggregate liability to you under or in connection with these T&amp;Cs does not
                exceed:</p>

              <p>(a) the value of the bets and/or wagers you placed via your account in respect of the relevant bet/wager
                or product that gave rise to the relevant liability; and</p>

              <p>(b) the amount of applicable monies, where such monies have been misplaced by us.</p>

              <p>(c) &pound;10,000 in respect of any other liability.</p>

              <p>4. RBL shall not be liable, in contract, tort (including negligence) or for breach of statutory duty or
                in any other way for any of the following (whether incurred directly or indirectly):</p>

              <p>(a) loss of profits;</p>

              <p>(b) loss of business;</p>

              <p>(c) loss of revenue;</p>

              <p>(d) loss of opportunity;</p>

              <p>(e) loss of data;</p>

              <p>(f) loss of goodwill or reputation; or</p>

              <p>(g) any special, indirect, or consequential losses,&nbsp;whether or not such losses were within the
                contemplation of the parties at the date of these T&amp;Cs.&nbsp;</p>

              <p>5. Nothing in this Section F shall limit RBL &lsquo;s liability to pay the customer winnings or other
                sums properly owing to it, subject always to T&amp;Cs set out herein and the maximum winnings on products
                in Appendix Two to these T&amp;Cs.</p>

              <p><strong>F.</strong>&nbsp;<strong>RISK RELATED TO THE HOLDING OF CRYPTO CURRENCY/PEGGED TOKEN</strong></p>

              <p>PRICE VOLATILITY</p>

              <p>Cryptocurrencies have a&nbsp;high price volatility that you wouldn&rsquo;t expect to see with other asset
                types. RBL accepts no responsibility for fluctuations in the value of cryptocurrencies held.</p>

              <p>CUSTODY OF KEYS</p>

              <p>Your private keys function as a verification mechanism embedded in your crypto wallet, allowing you to
                sign and send transactions from your wallet balance. Some wallet providers allow users to retain full
                custody of their keys, only providing a means to generate a new wallet and interact with it. Other wallet
                providers will manage the private keys on behalf of users, providing access through a secure login
                portal.&nbsp;Finding the right wallet for you&nbsp;can be tricky, with both custodial and non-custodial
                wallets each having their own tradeoffs in terms of security and recoverability.</p>

              <p>SCAMMERS AND HACKERS</p>

              <p>It is always a good idea to practice good digital hygiene when browsing the internet and interacting with
                online services. Setting strong, unique passwords and enabling two-factor authentication where possible is
                especially important for cryptocurrencies. Adept hackers can also exploit vulnerabilities in software to
                steal your data or take control of your device, so it is crucially important to keep your software and
                operating system up to date.</p>

              <p>Cryptocurrency holders and users are also often targeted by scammers and tricksters. It is especially
                important to be wary of fake websites and phishing emails that pretend to be from reputable
                sources&mdash;no reputable crypto asset issuer or service provider will ask for your private keys or
                passwords.</p>

              <p>SMART CONTRACT RISK</p>

              <p>When interacting with smart contracts, keep in mind that because blockchain technology involves a lot of
                complex concepts, there are many opportunities for developers to make mistakes, or for bad actors to
                include deceptive or malicious code that aims to steal your funds.</p>

              <p>CENTRALIZATION AND GOVERNANCE RISK</p>

              <p>Because cryptocurrency projects often rely on contributions from many teams&mdash;from marketing to
                community support to development and R&amp;D&mdash;there are a lot of opportunities for mismanagement. It
                is possible, for example, that a project may not meet its development milestones, delivery dates, or the
                expectations of the community in general, which could negatively affect the value of the product.</p>

              <p><strong>G. COMPLAINTS, DISPUTES, GOVERNING LAW, AND JURISDICTION</strong></p>

              <p>1.&nbsp;RBL work diligently to ensure that your experience of dealing with us is a pleasant one. However,
                there may be occasions where you wish to make a complaint regarding RBL. Should you wish to make a
                complaint about Robet, or its services, then we request that you contact&nbsp;<a
                  href="#">support@coingames.fun</a>&nbsp;within 30 days of the incident
                occurring.</p>

              <p>RBL will attempt to resolve your complaint within 21 days of receipt. RBL reserve the right to request
                further information or documentation from you as and when we see fit. This will enable us to review your
                complaint in full.</p>

              <p>If your complaint is not resolved to a satisfactory conclusion for all concerned then the matter can be
                escalated to our regulator, the Isle of Man Gambling Supervision Commission by selecting the following
                link&nbsp;<a href="#" rel="noopener noreferrer"
                  target="_blank">https://www.gov.im/categories/business-and-industries/gambling-and-e-gaming/player-protection/#complaints</a>
              </p>

              <p>2. By accepting these T&amp;Cs and/or placing bets or wagers and/or making use (whether authorised or
                not) of the facilities offered by RBL (whether through the website or otherwise) and/or by using, visiting
                and/or accessing any part (including, but not limited to, sub-domains, source code and/or website APIs,
                whether visible or not) of the website, you irrevocably agree that the courts of the Isle of Man shall
                have exclusive jurisdiction to settle any dispute which may arise out of or in connection with these
                T&amp;Cs. Notwithstanding the foregoing, RBL shall be entitled to bring a claim against a customer in the
                court of the customer&rsquo;s country of domicile.</p>

              <ul>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.windowInfo {
  border-radius: 10px;
  background: #112a34;
  margin-top: 40px;
}

.areaNavigate {
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
  border-right: 1px solid rgb(255, 255, 255);
  height: 100%;
  width: 100%;
}

@media (max-width: 1190px) {
  .areaNavigate {
    display: none;
  }
}

.field__space {
  padding: 15px 10px 15px 25px;
  border-bottom: 1px solid rgb(255, 255, 255);
}

.areaNavigate a:last-of-type .field__space {
  border-bottom: none;
}


.field__space:hover {
  background-color: #d4145a;
  transition: all 0.4s linear;
}

// Field

.textArea {
  padding: 40px;
  text-align: left;
  font-size: 1.6rem;


  &__title {
    font-size: 3rem;
    padding-bottom: 20px;
  }

  &__discord {
    padding-bottom: 20px;

    display: flex;
    align-items: center;

    &__time {
      padding-right: 18px;
      font-weight: 800;
    }
  }

  &__contact {
    display: flex;

    &__mail {

      &__fieldMail {
        color: #667b83;
        font-size: 1.2rem;
        align-items: center;
      }

      &__adresMail {
        color: #d4145a;
        font-weight: 600;
      }
    }

    &__anserTime {

      padding-left: 50px;

      &__field {
        color: #667b83;
        font-size: 1.2rem;
        align-items: center;
      }

      &__timeAnser {
        color: #d4145a;
        font-weight: 600;
      }
    }
  }

  &__fieldLine {
    height: 2px;
    margin: 15px 0 20px 0;
    background: rgb(255, 255, 253, 0.5);
    opacity: 1;

  }

  &__name {
    color: #667b83;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }

  &__inputName {
    margin-bottom: 25px;
  }


  &__mail {
    color: #667b83;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }

  &__inputMail {
    margin-bottom: 25px;
  }

  &__theme {
    color: #667b83;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }

  &__inputTheme {
    margin-bottom: 25px;
  }

  &__mesg {
    color: #667b83;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }

  &__inputMesg {
    margin-bottom: 25px;
  }

}

.input__style {
  appearance: none;
  color: #000;
  display: block;
  width: 100%;
  height: 40px;
  padding: 10px 40px 10px 10px;
  outline: none;
  border: 2px solid #667b83;
  border-radius: 10px;
}

.styleButton {
  color: aliceblue;
  background: #d4145a;
  border: 2px solid #d4145a;
  border-radius: 3px;
  padding: 5px 20px;
}

.styleButton:hover {
  color: aliceblue;
  background: #d4145a;
  box-shadow: 0 0 15px #ff3d84;
  transition: all 0.4s linear;
}

.massange {
  min-height: 230px;
}

.active {
  background-color: #d4145a;
}


a {
  color: inherit;
  text-decoration: none;
  border: none;
}

</style>