<template>
  <div class="home">
    <HeaderSectionNEW />
    <SideBarNew/>
    <SideBarBotMobile/>
    <PromoArea/>
    <FooterSection />
  </div>
</template>


<script>

import HeaderSectionNEW from "@/components/HeaderSectionNEW.vue";
import SideBarBotMobile from "@/components/SideBarBotMobile.vue"
import SideBarNew from "@/components/SideBarNew.vue";
import FooterSection from "@/components/FooterSection.vue";
import PromoArea from "@/components/PromoPage/PromoArea.vue"
import 'animate.css';
import "@fontsource/inter";


export default {
  name: "PromoPage",
  components: {
    HeaderSectionNEW,
    SideBarBotMobile,
    SideBarNew,
    FooterSection,
    PromoArea,
  },
};
</script>