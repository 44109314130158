<template>
  <div class="BestWinnerArea">
    <div class="container">
      <div class="row">
        <div class="col">
          <h2 class="banner__title text-start">Recent wins 🤑</h2>
        </div>
      </div>

      <div class="tableWins">
        <div class="row">
          <div class="borderHead gameFieldStyleHead">
            <div class="col-4 gameFieldStyle">Игра</div>
            <div class="col-2 gameFieldStyle">Игрок</div>
            <div class="col-2 gameFieldStyle">Страна</div>
            <div class="col-2 gameFieldStyle">Время</div>
            <div class="col-2 gameFieldStyle">Сумма</div>
          </div>
        </div>

        <div class="row">
          <div class="borderBody gameFieldStyleHead">
            <div class="col-4 gameFieldStyleBody">
              <div class="">
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/CashX9990.png"
                  alt=""
                />
              </div>
              <div class="styleGameName">CashX9990</div>
            </div>
            <div class="col-2 gameFieldStyleBody">MarkZero</div>
            <div class="col-2 gameFieldStyleBody">
              <img class="styleGameName" src="@/assets/img/flags/eng.png" />
            </div>
            <div class="col-2 gameFieldStyleBody">2s ago</div>
            <div class="col-2 gameFieldStyleBody">43 001</div>
          </div>
        </div>

        <div class="row">
          <div class="borderBody gameFieldStyleHead">
            <div class="col-4 gameFieldStyleBody">
              <div class="">
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/Baloon.png"
                  alt=""
                />
              </div>
              <div class="styleGameName">Balloon</div>
            </div>
            <div class="col-2 gameFieldStyleBody">ID90766435</div>
            <div class="col-2 gameFieldStyleBody">
              <img class="styleGameName" src="@/assets/img/flags/ua.png" />
            </div>
            <div class="col-2 gameFieldStyleBody">3s ago</div>
            <div class="col-2 gameFieldStyleBody">129 531</div>
          </div>
        </div>
        <div class="row">
          <div class="borderBody gameFieldStyleHead">
            <div class="col-4 gameFieldStyleBody">
              <div class="">
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/coinSpace.png"
                  alt=""
                />
              </div>
              <div class="styleGameName">CoinSpace</div>
            </div>
            <div class="col-2 gameFieldStyleBody">BONUS</div>
            <div class="col-2 gameFieldStyleBody">
              <img class="styleGameName" src="@/assets/img/flags/ua.png" />
            </div>
            <div class="col-2 gameFieldStyleBody">5s ago</div>
            <div class="col-2 gameFieldStyleBody">1 000 000</div>
          </div>
        </div>

        <div class="row">
          <div class="borderBody gameFieldStyleHead">
            <div class="col-4 gameFieldStyleBody">
              <div class="">
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/coinSpace.png"
                  alt=""
                />
              </div>
              <div class="styleGameName">CoinSpace</div>
            </div>
            <div class="col-2 gameFieldStyleBody">BONUS</div>
            <div class="col-2 gameFieldStyleBody">
              <img class="styleGameName" src="@/assets/img/flags/ua.png" />
            </div>
            <div class="col-2 gameFieldStyleBody">5s ago</div>
            <div class="col-2 gameFieldStyleBody">1 000 000</div>
          </div>
        </div>

        <!-- mobile -->

        <div class="row">
          <div class="borderBody-M gameFieldStyleHead-M">

            <div class="col-3" style="align-self:center;">
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/Baloon.png"
                  alt=""
                />
              </div>

            <div class="col-3 gameFieldStyleBody">
              <div class="col-6">
                <div class="col-6 styleGameName-M">Balloon</div>

                <div class="col-6 d-flex">
                  <div class="col-11 gameFieldStyleBody-M">BONUS</div>
                </div>
              </div>
            </div>

            <div class="col-2 gameFieldStyleBody-M">
              <img class="styleGameName-M" src="@/assets/img/flags/ge.png" />
            </div>

            <div class="col-4">
              <div class="col-12 gameFieldStyleBody-M">5s ago</div>
              <div class="col-12 gameFieldStyleBody-M">1 000 000</div>
            </div>
          </div>
        </div>

        
        <div class="row">
          <div class="borderBody-M gameFieldStyleHead-M">

            <div class="col-3" style="align-self:center;">
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/Baloon.png"
                  alt=""
                />
              </div>

            <div class="col-3 gameFieldStyleBody">
              <div class="col-6">
                <div class="col-6 styleGameName-M">Balloon</div>

                <div class="col-6 d-flex">
                  <div class="col-11 gameFieldStyleBody-M">BONUS</div>
                </div>
              </div>
            </div>

            <div class="col-2 gameFieldStyleBody-M">
              <img class="styleGameName-M" src="@/assets/img/flags/ge.png" />
            </div>

            <div class="col-4">
              <div class="col-12 gameFieldStyleBody-M">5s ago</div>
              <div class="col-12 gameFieldStyleBody-M">1 000 000</div>
            </div>
          </div>
        </div>

        
        <div class="row">
          <div class="borderBody-M gameFieldStyleHead-M">

            <div class="col-3" style="align-self:center;">
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/Baloon.png"
                  alt=""
                />
              </div>

            <div class="col-3 gameFieldStyleBody">
              <div class="col-6">
                <div class="col-6 styleGameName-M">Balloon</div>

                <div class="col-6 d-flex">
                  <div class="col-11 gameFieldStyleBody-M">BONUS</div>
                </div>
              </div>
            </div>

            <div class="col-2 gameFieldStyleBody-M">
              <img class="styleGameName-M" src="@/assets/img/flags/ge.png" />
            </div>

            <div class="col-4">
              <div class="col-12 gameFieldStyleBody-M">5s ago</div>
              <div class="col-12 gameFieldStyleBody-M">1 000 000</div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="borderBody-M gameFieldStyleHead-M">

            <div class="col-3" style="align-self:center;">
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/Baloon.png"
                  alt=""
                />
              </div>

            <div class="col-3 gameFieldStyleBody">
              <div class="col-6">
                <div class="col-6 styleGameName-M">Balloon</div>

                <div class="col-6 d-flex">
                  <div class="col-11 gameFieldStyleBody-M">BONUS</div>
                </div>
              </div>
            </div>

            <div class="col-2 gameFieldStyleBody-M">
              <img class="styleGameName-M" src="@/assets/img/flags/ge.png" />
            </div>

            <div class="col-4">
              <div class="col-12 gameFieldStyleBody-M">5s ago</div>
              <div class="col-12 gameFieldStyleBody-M">1 000 000</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="BestWinnerArea">
    <div class="container">
      <div class="row">
        <div class="col">
          <h2 class="banner__titleTwo text-start">Biggest wins 🏆</h2>
        </div>
      </div>

      <div class="tableWins">
        <div class="row">
          <div class="borderHead gameFieldStyleHead">
            <div class="col-4 gameFieldStyle">Игра</div>
            <div class="col-2 gameFieldStyle">Игрок</div>
            <div class="col-2 gameFieldStyle">Страна</div>
            <div class="col-2 gameFieldStyle">Время</div>
            <div class="col-2 gameFieldStyle">Сумма</div>
          </div>
        </div>
        <div class="row">
          <div class="borderBody gameFieldStyleHead">
            <div class="col-4 gameFieldStyleBody">
              <div>
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/coinSpace.png"
                  alt=""
                />
              </div>
              <div class="styleGameName">Balloon</div>
            </div>
            <div class="col-2 gameFieldStyleBody">MarkZero</div>
            <div class="col-2 gameFieldStyleBody">
              <img class="styleGameName" src="@/assets/img/flags/eng.png" />
            </div>
            <div class="col-2 gameFieldStyleBody">2s ago</div>
            <div class="col-2 gameFieldStyleBody">43 001</div>
          </div>
        </div>
        <div class="row">
          <div class="borderBody gameFieldStyleHead">
            <div class="col-4 gameFieldStyleBody">
              <div>
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/coinSpace.png"
                  alt=""
                />
              </div>
              <div class="styleGameName">Balloon</div>
            </div>
            <div class="col-2 gameFieldStyleBody">MarkZero</div>
            <div class="col-2 gameFieldStyleBody">
              <img class="styleGameName" src="@/assets/img/flags/eng.png" />
            </div>
            <div class="col-2 gameFieldStyleBody">2s ago</div>
            <div class="col-2 gameFieldStyleBody">43 001</div>
          </div>
        </div>
        <div class="row">
          <div class="borderBody gameFieldStyleHead">
            <div class="col-4 gameFieldStyleBody">
              <div>
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/Baloon.png"
                  alt=""
                />
              </div>
              <div class="styleGameName">Balloon</div>
            </div>
            <div class="col-2 gameFieldStyleBody">BONUS</div>
            <div class="col-2 gameFieldStyleBody">
              <img class="styleGameName" src="@/assets/img/flags/ge.png" />
            </div>
            <div class="col-2 gameFieldStyleBody">5s ago</div>
            <div class="col-2 gameFieldStyleBody">1 000 000</div>
          </div>
        </div>

        <div class="row">
          <div class="borderBody gameFieldStyleHead lastChild">
            <div class="col-4 gameFieldStyleBody">
              <div>
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/Baloon.png"
                  alt=""
                />
              </div>
              <div class="styleGameName">Balloon</div>
            </div>
            <div class="col-2 gameFieldStyleBody">WinPanda</div>
            <div class="col-2 gameFieldStyleBody">
              <img class="styleGameName" src="@/assets/img/flags/ua.png" />
            </div>
            <div class="col-2 gameFieldStyleBody">12s ago</div>
            <div class="col-2 gameFieldStyleBody">66 096</div>
          </div>
        </div>

<!-- mobile -->
        <div class="row">
          <div class="borderBody-M gameFieldStyleHead-M">

            <div class="col-3" style="align-self:center;">
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/Baloon.png"
                  alt=""
                />
              </div>

            <div class="col-3 gameFieldStyleBody">
              <div class="col-6">
                <div class="col-6 styleGameName-M">Balloon</div>

                <div class="col-6 d-flex">
                  <div class="col-11 gameFieldStyleBody-M">BONUS</div>
                </div>
              </div>
            </div>

            <div class="col-2 gameFieldStyleBody-M">
              <img class="styleGameName-M" src="@/assets/img/flags/ge.png" />
            </div>

            <div class="col-4">
              <div class="col-12 gameFieldStyleBody-M">5s ago</div>
              <div class="col-12 gameFieldStyleBody-M">1 000 000</div>
            </div>
          </div>
        </div>

        
        <div class="row">
          <div class="borderBody-M gameFieldStyleHead-M">

            <div class="col-3" style="align-self:center;">
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/Baloon.png"
                  alt=""
                />
              </div>

            <div class="col-3 gameFieldStyleBody">
              <div class="col-6">
                <div class="col-6 styleGameName-M">Balloon</div>

                <div class="col-6 d-flex">
                  <div class="col-11 gameFieldStyleBody-M">BONUS</div>
                </div>
              </div>
            </div>

            <div class="col-2 gameFieldStyleBody-M">
              <img class="styleGameName-M" src="@/assets/img/flags/ge.png" />
            </div>

            <div class="col-4">
              <div class="col-12 gameFieldStyleBody-M">5s ago</div>
              <div class="col-12 gameFieldStyleBody-M">1 000 000</div>
            </div>
          </div>
        </div>

        
        <div class="row">
          <div class="borderBody-M gameFieldStyleHead-M">

            <div class="col-3" style="align-self:center;">
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/Baloon.png"
                  alt=""
                />
              </div>

            <div class="col-3 gameFieldStyleBody">
              <div class="col-6">
                <div class="col-6 styleGameName-M">Balloon</div>

                <div class="col-6 d-flex">
                  <div class="col-11 gameFieldStyleBody-M">BONUS</div>
                </div>
              </div>
            </div>

            <div class="col-2 gameFieldStyleBody-M">
              <img class="styleGameName-M" src="@/assets/img/flags/ge.png" />
            </div>

            <div class="col-4">
              <div class="col-12 gameFieldStyleBody-M">5s ago</div>
              <div class="col-12 gameFieldStyleBody-M">1 000 000</div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="borderBody-M gameFieldStyleHead-M">

            <div class="col-3" style="align-self:center;">
                <img
                  class="styleGame"
                  src="@/assets/img/recentWins/Baloon.png"
                  alt=""
                />
              </div>

            <div class="col-3 gameFieldStyleBody">
              <div class="col-6">
                <div class="col-6 styleGameName-M">Balloon</div>

                <div class="col-6 d-flex">
                  <div class="col-11 gameFieldStyleBody-M">BONUS</div>
                </div>
              </div>
            </div>

            <div class="col-2 gameFieldStyleBody-M">
              <img class="styleGameName-M" src="@/assets/img/flags/ge.png" />
            </div>

            <div class="col-4">
              <div class="col-12 gameFieldStyleBody-M">5s ago</div>
              <div class="col-12 gameFieldStyleBody-M">1 000 000</div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<style>
.BestWinnerArea {
  padding: 35px 16px 0 16px;
}

.banner__title {
  font-weight: 700;
  font-size: 32px;
}

@media (max-width: 700px) {
  .banner__title {
    font-size: 20px;
  }
}

.tableWins {
  padding-top: 30px;
}

.gameFieldStyleHead {
  display: flex;
  margin: 0 auto;
  width: 99%;
}

.borderHead {
  padding: 20px 20px;
  border-radius: 19px;
  background-color: #7647dc;
  border: none;
  margin-bottom: 15px;
}

@media (max-width: 1000px) {
  .borderHead {
    display: none;
  }
}

.borderBody {
  padding: 20px 20px;
  border-radius: 19px;
  background-color: #131420;
  border: none;
  margin-bottom: 15px;
}

@media (max-width: 700px) {
  .borderBody {
    padding: 15px 15px;
  }
}

.gameFieldStyle {
  text-align: left;
  font-weight: 700;
  font-size: 14px;
}

.gameFieldStyleBody {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.styleGame {
  width: 90px;
}

@media (max-width: 700px) {
  .styleGame {
    width: 58px;
  }
}

.styleGameFlag {
  width: 75px;
}

.styleGameName {
  padding-left: 10px;
}

@media (max-width: 700px) {
  .styleGameName {
    padding-left: 10px;
    width: 23px;
  }
}

.lastChild {
  margin-bottom: 0px;
}

.banner__titleTwo {
  padding-top: 0px;
  font-weight: 700;
  font-size: 32px;
}

@media (max-width: 650px) {
  .banner__titleTwo {
    padding-top: 0px;
  }
}

@media (max-width: 780px) {
  .banner__titleTwo {
    font-size: 20px;
  }
}

@media (max-width: 650px) {
  .borderBody.gameFieldStyleHead {
    display: none;
  }
}


@media screen and (min-width: 650px) {
  .borderBody-M.gameFieldStyleHead-M {
    display: none;
  }
}


/* Mobile */

.borderBody-M {
  padding: 15px 15px;
  border-radius: 19px;
  background-color: #131420;
  border: none;
  margin-bottom: 15px !important;
}

.gameFieldStyleHead-M {
  display: flex;
  margin: 0 auto;
  width: 99%;
}

.gameFieldStyleBody-M {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.styleGameName-M {
  padding-left: 0px;
  width: 25px;
}

</style>
