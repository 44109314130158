<template>
  <div class="infoArea">
    <div class="container text-light">
      <div class="windowInfo">
        <div class="row">
          <div class="col-xl-4">
            <div class="areaNavigate a-noDeciration">

              <a href="/contacts/contactus">
                <div class="contactUS field__space">Связатся с нами</div>
              </a>

              <!-- <div class="blog field__space">Блог</div> -->

              <a href="/contacts/politics">
                <div class="politicsConf field__space active">Политикой Конфиденциальности</div>
              </a>

              <a href="/contacts/respons">
                <div class="gameRespons field__space">Responsible Gambling</div>
              </a>
              <a href="/contacts/fairgames">
                <div class="rulesGame field__space">Честная игра</div>
              </a>
              <a href="/contacts/rules">
                <div class="contactUS field__space">Правила игр</div>
              </a>
              <a href="/contacts/terms">
              <div class="termsCondit field__space">Условиями и положениями</div>
            </a>

            </div>
          </div>
          <div class="col-xl-8 col-l2">
            <div class="textArea">
              <div class="row">
                <h1>Политикой Конфиденциальности</h1>

                <p>&nbsp;</p>

                <h2>What is the Privacy Policy?</h2>

                <p>Privacy Policy<br />
                  The following statement sets out Our policy relating to the collection, storage and use of personal
                  information in the course of its business.</p>

                <p>In this policy:</p>

                <p>(a) &ldquo;we&quot;, &ldquo;us&quot;, &ldquo;our&quot; or &ldquo;Casino&quot; means , which operates
                  under company,&nbsp;registration no.,&nbsp;registered at , license .</p>

                <p>We take seriously our obligation to safeguard personal information about our clients.</p>

                <p>This Privacy Policy is in addition to Our&nbsp;Terms un Use&nbsp;and should be read in conjunction with
                  the Terms un Use.</p>

                <p>By using this website, opening a Betting Account or placing a wager with Us via website or apps, you
                  agree to be bound by the terms of this Privacy Policy.</p>

                <p>We review our Privacy Policy from time to time and reserve the right, at our discretion, to amend this
                  policy at any time without any notice other than posting the amended Privacy Policy on the Our website
                  or apps. Amendments to our Privacy Policy will be effected immediately once posted on our website or
                  apps. Your continued use of our services following the posting of an amended Privacy Policy will be
                  deemed as acceptance of those amendments.</p>

                <p>It is your responsibility to ensure that you keep up-to-date with the current Privacy Policy.</p>

                <p>You should review this page periodically so that you are updated on any changes to the policy.</p>

                <h2>Personal Information</h2>

                <p>Types of Personal Information we collect and hold</p>

                <p>We collect a variety of personal information in the course of our business.</p>

                <p>The types of personal information that we may collect and hold includes, without limitation:</p>

                <ul>
                  <li>identification information such as your name, date of birth, residential and postal address, gender,
                    email address, telephone numbers;</li>
                  <li>financial information;</li>
                  <li>copies of identification documents such as drivers licence, passport, birth certificate and/or
                    utility bills;</li>
                  <li>personally submitted preferences;</li>
                  <li>recordings of telephone conversations and website and app activity;</li>
                  <li>responses to competitions, other promotions and surveys; and</li>
                  <li>profession, occupation or job title.</li>
                </ul>

                <h2>How we collect Personal Information</h2>

                <p>We will collect personal information only by lawful and fair means.</p>

                <p>We collect your personal information directly from you unless it is unreasonable or impracticable to do
                  so. We may collect this information when you:</p>

                <ul>
                  <li>register for an account with Us;</li>
                  <li>access or use our website or apps;</li>
                  <li>use our products and services;</li>
                  <li>participate in our competitions, giveaways and other promotions;</li>
                  <li>contact us directly via any medium including: SMS, MMS, instant messaging, email, social media
                    platforms, postal mail and telephone;</li>
                  <li>provide feedback through our website/app feedback links; and</li>
                </ul>

                <p>It may also be necessary to collect your personal information from third parties, including:</p>

                <ul>
                  <li>credit reporting agencies, law enforcement agencies and other government entities;</li>
                  <li>identify verification providers;</li>
                </ul>

                <p>Personal information may also be collected by cookies when you use our website and apps. See Cookies
                  below for further information.</p>

                <h2>Why we collect, hold, use and disclose personal information</h2>

                <p>We collect and hold, use and disclose personal information for purposes including the following:</p>

                <ul>
                  <li>to allow you to register as a client of Ours;</li>
                  <li>to provide products and services to you;</li>
                  <li>to enable us to perform our obligations to you under Terms of Use, and to ensure that you perform
                    your obligations under Terms of Use;</li>
                  <li>for communicating with you, including sending you information about our products and services;</li>
                  <li>for planning, research, promotion and marketing of our goods and services, including conducting
                    competitions or promotions;</li>
                  <li>to create aggregate data about clients through demographic profiling and statistical analysis of our
                    database to optimise our products and services and /or allow for more efficient operation of our
                    business;</li>
                  <li>to maintain a credit information file on you and to carry out a credit assessment on you;</li>
                  <li>to establish your bona fides;</li>
                  <li>for the investigation of suspected unlawful, fraudulent or other improper activity connected with
                    the use of our products and services; and</li>
                  <li>to comply with our legal and statutory obligations, including our obligations relating to identity
                    verification and reporting under the Anti-Money Laundering and Counter Terrorism legislation and other
                    applicable laws.</li>
                </ul>

                <h2>To whom Personal Information is disclosed</h2>

                <p>By registering with Us and providing personal information you consent to your personal information
                  being used by us and other entities in the Our group of companies.</p>

                <p>We share personal information with other entities in Our group of companies in a strictly controlled
                  manner and do not sell personal information to other companies.</p>

                <p>We may disclose your personal information to third parties as follows:</p>

                <ul>
                  <li>to a credit reporting agency;</li>
                  <li>to our contractors and external service providers associated with the operation of our business and
                    provision of our services including, without limitation, associated data centres, web hosting
                    providers, payment service providers, identification verification service providers, advertising
                    agencies, mailing houses, printers, call centres, market research analysts, IT consultants,
                    professional advisors and consultants;</li>
                  <li>to law enforcement agencies to assist in the prevention of criminal activities;</li>
                  <li>to government and regulatory authorities and other organisations as required or authorised by law or
                    otherwise;</li>
                  <li>controlling bodies where such controlling bodies request information to protect and maintain the
                    integrity of services provided or where we consider any betting or gaming activity to be suspicious or
                    unusual; and</li>
                  <li>to a successor entity in the event of a business transition, such as a merger, corporate
                    reorganisation or to a purchaser of part of or all of Our assets.</li>
                </ul>

                <p>Should the information be transferred to a third party, we will use reasonable endeavours to ensure
                  that the information disclosed is protected by the third party under contractual arrangements.</p>

                <h2>Security of Personal Information</h2>

                <p>We will take reasonable steps to protect the personal information we collect and ensure that it is
                  accurate, complete and up-to-date.</p>

                <p>Your information is held on secure servers. We may also store personal information in telephone
                  recordings and in hard copy or paper files.</p>

                <p>Our employees, agents, contractors are required to maintain the confidentiality of all personal
                  information.</p>

                <p>You are responsible for ensuring that you keep your username, password and account information
                  confidential. If you suspect that your details may no longer be confidential, you should notify Us
                  immediately, whereupon new details may be given.</p>

                <p>You are responsible for the security of and access to your own computer/device.&nbsp; You should ensure
                  that you always log out of your account after each use of the website/app.</p>

                <p>We understand the importance of security and techniques needed to secure information. We store all of
                  the Personal Information we receive directly from you in the protected database residing within our
                  secure network behind active state-of-the-art firewall software. As well as we do not store or process
                  credentials of payment cards. Our Services support SSL Versions at least TLS 1.2, but mostly TLS 1.3
                  with 256-bit encryption. HTTP/2 and QUIC protocols are supported as well.</p>

                <h2>Access to and correcting Personal Information</h2>

                <p>We take reasonable steps to ensure that the personal information we collect about you is accurate,
                  up-to-date and complete. We also take reasonable steps to ensure that the personal information we use or
                  disclose is accurate up-to-date, complete and relevant.</p>

                <p>If we are satisfied that your personal information should be corrected we will take reasonable steps to
                  correct it &ndash; this may include contacting you to seek your most current information.</p>

                <p>You may request access to and correction of the personal information we hold about you. We will require
                  you to verify your identity and to specify what information you require.</p>

                <p>You may also update or correct some of your personal information via the &ldquo;My Account&quot;
                  section of the website/apps.</p>

                <h2>Overseas transfer of personal information</h2>

                <p>There may be instances where we may be required to send your personal information overseas or collect
                  personal information from overseas, including:</p>

                <ul>
                  <li>where you have asked us to do so;</li>
                  <li>when we have outsourced a business activity or function to an overseas provider with whom we have a
                    contractual arrangement; or</li>
                  <li>where we are required or authorised by law to do so.</li>
                </ul>

                <p>We may disclose your personal information to entities located outside your country of residence,
                  including the following:</p>

                <ul>
                  <li>companies in the Our group of companies; and</li>
                  <li>external service providers located.</li>
                </ul>

                <h2>Unsolicited personal information</h2>

                <p>Where we receive unsolicited personal information, we will determine whether or not it would have been
                  permissible to collect that personal information if it had been solicited. If we determine that
                  collection would not have been permissible, to the extent permitted by law, we will destroy or
                  de-identify that personal information as soon as practicable.</p>

                <h2>Non-Personal Information</h2>

                <p>Like most websites and apps, we may collect non-personal information from your use of our websites and
                  apps in order to assist with the administration of our websites and apps, to track site usage and to
                  enhance your user experience.</p>

                <p>Once logged in your IP address is tracked against your user ID for the &nbsp;purposes of preventing
                  fraud, identifying improper activity connected with the use of our products and services, and creating
                  an audit trail. We collect and store non-personal information that we collect through cookies, log files
                  or web beacons to create a profile of our users. A profile is stored information that we keep on
                  individual users that detail their viewing preferences. This profile is used to tailor a user&rsquo;s
                  visit to the Oursite and apps, and to direct pertinent marketing promotions to users.</p>

                <h2>Cookies</h2>

                <p>Cookies&nbsp;are small pieces of data which are stored by a website through your browser, to enable an
                  improved experience whilst using or browsing a particular website/app or to remember your preferences or
                  navigation history/activity (such as pages you have visited within a particular site or typical
                  activity) at a particular site</p>

                <p>We may use&nbsp;cookies to remember your preferences when you have visited our site and pages you like
                  to regularly visit and to ensure that you receive the most up to date information which is relevant to
                  you and your experience with Us. We also use cookies for internal management purposes and to enable
                  essential activity to ensure that your account works correctly when accessing our services.</p>

                <p>We will not use these cookies to store information such as account details or transaction history.</p>

                <p>You can delete cookies using your browsers own Clear History function. You can reset your browser to
                  refuse all cookies or to indicate when a cookie is being sent. However, some website and app features or
                  services may not function properly without cookies.</p>

                <p>Most web browsers allow some control of cookies through the browser settings. It is important to note
                  however that if you block cookies from Our site or apps there are a number of functions that may not
                  work and you will need to reset your preferences each time you log in.</p>

                <h2>Third Party cookies</h2>

                <p>We may use third-parties to serve ads on our website/apps. These companies may place or recognise
                  cookies, action tags and other technology to measure advertising effectiveness.<br />
                  We may use cookies to remarket to you across the internet.<br />
                  The use of cookies allows Us to inform, optimize, and serve ads based on someone&rsquo;s past visits to
                  our website/apps.<br />
                  To specifically opt-out of interest based ad targeting&nbsp;<strong><a
                      href="#">click
                      here</a></strong>&nbsp;and follow the instructions.</p>

                <p>Find out more about cookies<br />
                  To find out more about cookies, including how to see what cookies have been set and how to manage and
                  delete them, visit&nbsp;<a href="#">www.allaboutcookies.org</a>.</p>

                <p>Complaints</p>

                <p>If you have a complaint relating to how we handle personal information, please contact our support at
                  support@.</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.windowInfo {
  border-radius: 10px;
  background: #112a34;
  margin-top: 40px;
}

.areaNavigate {
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
  border-right: 1px solid rgb(255, 255, 255);
  height: 100%;
  width: 100%;
}

@media (max-width: 1190px) {
  .areaNavigate {
    display: none;
  }
}

.field__space {
  padding: 15px 10px 15px 25px;
  border-bottom: 1px solid rgb(255, 255, 255);
}

.areaNavigate a:last-of-type .field__space {
  border-bottom: none;
}


.field__space:hover {
  background-color: #d4145a;
  transition: all 0.4s linear;
}

// Field

.textArea {
  padding: 40px;
  text-align: left;
  font-size: 1.6rem;


  &__title {
    font-size: 3rem;
    padding-bottom: 20px;
  }

  &__discord {
    padding-bottom: 20px;

    display: flex;
    align-items: center;

    &__time {
      padding-right: 18px;
      font-weight: 800;
    }
  }

  &__contact {
    display: flex;

    &__mail {

      &__fieldMail {
        color: #667b83;
        font-size: 1.2rem;
        align-items: center;
      }

      &__adresMail {
        color: #d4145a;
        font-weight: 600;
      }
    }

    &__anserTime {

      padding-left: 50px;

      &__field {
        color: #667b83;
        font-size: 1.2rem;
        align-items: center;
      }

      &__timeAnser {
        color: #d4145a;
        font-weight: 600;
      }
    }
  }

  &__fieldLine {
    height: 2px;
    margin: 15px 0 20px 0;
    background: rgb(255, 255, 253, 0.5);
    opacity: 1;

  }

  &__name {
    color: #667b83;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }

  &__inputName {
    margin-bottom: 25px;
  }


  &__mail {
    color: #667b83;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }

  &__inputMail {
    margin-bottom: 25px;
  }

  &__theme {
    color: #667b83;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }

  &__inputTheme {
    margin-bottom: 25px;
  }

  &__mesg {
    color: #667b83;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }

  &__inputMesg {
    margin-bottom: 25px;
  }

}

.input__style {
  appearance: none;
  color: #000;
  display: block;
  width: 100%;
  height: 40px;
  padding: 10px 40px 10px 10px;
  outline: none;
  border: 2px solid #667b83;
  border-radius: 10px;
}

.styleButton {
  color: aliceblue;
  background: #d4145a;
  border: 2px solid #d4145a;
  border-radius: 3px;
  padding: 5px 20px;
}

.styleButton:hover {
  color: aliceblue;
  background: #d4145a;
  box-shadow: 0 0 15px #ff3d84;
  transition: all 0.4s linear;
}

.massange {
  min-height: 230px;
}

.active {
  background-color: #d4145a;
}

a {
  color: inherit;
  text-decoration: none;
  border: none;
}
</style>