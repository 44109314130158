<template>
  <div class="home">
    <HeaderSectionNEW />
    <SideBarNew/>
    <SideBarBotMobile/>
    <BannerSection />
    <MenuCegatoryofLiveCasino/>
    <LiveRouletteArea/>
    <FooterSection />
  </div>
</template>


<script>
import HeaderSectionNEW from "@/components/HeaderSectionNEW.vue";
import SideBarBotMobile from "@/components/SideBarBotMobile.vue"
import SideBarNew from "@/components/SideBarNew.vue";
import BannerSection from "@/components/BannerSection.vue";
import MenuCegatoryofLiveCasino from "@/components/liveCasino/MenuCategoryofLiveCasino.vue";
import LiveRouletteArea from "@/components/liveCasino/LiveRouletteArea.vue"

import FooterSection from "@/components/FooterSection.vue";
import 'animate.css';
import "@fontsource/inter";



export default {
  name: "LiveCasino",
  components: {
    HeaderSectionNEW,
    SideBarBotMobile,
    SideBarNew,
    MenuCegatoryofLiveCasino,
    BannerSection,
    LiveRouletteArea,
    FooterSection,
    
    
  },
};
</script>