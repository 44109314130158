<template>
  <div class="Tournament">
    <div class="Title">
      <div class="container">
        <div class="row">
          <div class="col-10">
            <div class="titleIcoPosition">
              <div>
                <img
                  class="titleIco"
                  src="@/assets/img/tournamnent/ico.svg"
                  alt=""
                />
              </div>
              <div class="banner">
                <h2 class="banner__title text-white">Турниры</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="background">
          <div class="row">
            <div class="d-none d-lg-block col-xl-6 col-xxl-6">
              <div class="leftSide">
                <img
                  class="leftSide__ico"
                  src="@/assets/img/tournamnent/chips.png"
                  alt=""
                />
              </div>
            </div>
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-6 col-xxl-6"
            >
              <div class="rightSide">
                <div class="place">
                  <div class="place__title">Create a casino with 🔥</div>

                  <div class="place__col">
                    <div class="place__col__des">
                      <div class="place__col__des__one">1 место</div>
                      <div class="place__col__des__two">15 000 грн</div>
                    </div>
                    <div class="place__col__des">
                      <div class="place__col__des__one">1 место</div>
                      <div class="place__col__des__two">15 000 грн</div>
                    </div>
                    <div class="place__col__des">
                      <div class="place__col__des__one">1 место</div>
                      <div class="place__col__des__two">15 000 грн</div>
                    </div>
                  </div>

                  <div class="place__win">
                    <div class="place__win__fond">
                      <div class="place__win__fond__style">Призовий фонд</div>
                      <div class="place__win__fond__style1">50 000</div>
                    </div>
                    <div class="place__win__end">
                      <div class="place__win__end__style">До завершення</div>
                      <div class="place__win__end__style1">6 дней</div>
                    </div>
                  </div>

                  <div class="lineBot"></div>

                  <div class="buttonPlace">
                    <div class="buttonPlace__buttonOne">
                      <button class="buttonPlace__buttonOne__style">
                        Add a casino 🎰
                      </button>
                    </div>
                    <div class="buttonPlace__buttonTwo">
                      <button class="buttonPlace__buttonTwo__style">
                        More details 🃏
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.background {
  border-radius: 19px;
  background: linear-gradient(84deg, #131420 33.85%, #7647dc 100%);
}
.Tournament {
  padding-top: 63px;
}

.titleIcoPosition {
  display: flex;
}

.banner {
  padding-left: 18px;
}

.banner__title {
  font-size: 32px;
  font-weight: 700;
  text-align: start;
  padding-bottom: 47px;
}

.leftSide {
  &__ico {
    // width: 70%;
    vertical-align: middle;
  }
}

.rightSide {
  width: 100%;
}
.place {
  padding: 37px 80px 35px 74px;
  text-align: left;

  &__title {
    font-size: 32px;
    font-weight: 700;
  }

  &__col {
    padding-top: 25px;
    display: flex;
    justify-content: space-between;

    &__des {
      padding: 6px 39px 6px 15px;
      border-radius: 19px;
      background: rgba(255, 255, 255, 0.1);

      &__one {
        color: #d9d9d9;
        font-size: 14px;
        font-weight: 500;
        text-transform: lowercase;
      }

      &__two {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  &__win {
    padding-top: 27px;
    display: flex;
    justify-content: left;

    &__fond {
      padding-right: 137px;

      &__style {
        font-size: 14px;
        font-weight: 500;
      }

      &__style1 {
        padding-top: 11px;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    &__end {
      &__style {
        font-size: 14px;
        font-weight: 500;
      }

      &__style1 {
        padding-top: 11px;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
}

.lineBot {
  margin-top: 11px;
  width: 100%;
  height: 1px;
  background: #d9d9d9;
}

.buttonPlace {
  padding-top: 22px;
  display: flex;

  &__buttonOne {
    &__style {
      border-radius: 20px;
      background: #7647dc;
      width: 152px;
      height: 48px;

      font-size: 14px;
      font-weight: 500;
      color: #fff;
      border: transparent;
    }
  }

  &__buttonTwo {
    padding-left: 32px;

    &__style {
      border-radius: 20px;
      background: transparent;
      width: 152px;
      height: 48px;

      font-size: 14px;
      font-weight: 500;
      color: #fff;
      border: transparent;
    }
  }
}

@media (max-width: 1400px) {
  .place {
    padding: 37px 30px 35px 54px;
  }

  .lineBot {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .leftSide {
    display: none;
  }
}

@media (max-width: 500px) {
  .place {
    padding: 20px 20px;
  }

  .place__col__des {
    padding: 3% 4%;
  }
}

@media (max-width: 410px) {
  .place__title {
    font-size: 25px;
  }

  .place__col__des__one {
    font-size: 12px;
  }

  .place__col__des__two{
    font-size: 12px;
  }

  .buttonPlace__buttonTwo{
    padding-left: 3%;
  }
}
</style>
