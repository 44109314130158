<template>
  <div class="BestGameArea">
    <div class="container">
      <div class="row">
        <div class="col">
          <h2 class="banner__title text-start">All Games</h2>
        </div>
      </div>

      <div class="row row-cols-2 row-cols-md-2 row-cols-lg-4  row-cols-xl-4 row-cols-xxl-6">
        <div class="col">
          <div class="cartGame">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/pp570.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>
        <div class="col">
          <div class="cartGame">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/infin_evolutiongamingevolutiongr_american_roulette_pl5.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
            
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>

        <div class="col">
          <div class="cartGame">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/infin_evolutiongamingevolutiongr_crazy_times_pl5.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
             
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>

        <div class="col">
          <div class="cartGame">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/infin_evolutiongamingevolutiongr_dead_or_alive_saloon_pl5.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
             
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>

        <div class="col">
          <div class="cartGame ">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/infin_evolutiongamingevolutiongr_fp_lightning_roulette_pl5.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
             
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>

        <div class="col">
          <div class="cartGame ">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/infin_evolutiongamingevolutiongr_american_roulette_pl5.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
            
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>

        <div class="col">
          <div class="cartGame">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/infin_evolutiongamingevolutiongr_infinite_blackjack_pl5.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
            
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>

        <div class="col">
          <div class="cartGame ">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/infin_evolutiongamingevolutiongr_instant_roulette_pl5.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
            
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>

        <div class="col">
          <div class="cartGame ">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/infin_evolutiongamingevolutiongr_lightning_roulette_pl5.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
          
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>

        <div class="col">
          <div class="cartGame ">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/infin_evolutiongamingevolutiongr_american_roulette_pl5.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
             
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>

        <div class="col">
          <div class="cartGame ">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/infin_evolutiongamingevolutiongr_monopoly_live_pl5.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
            
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>

        <div class="col">
          <div class="cartGame ">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/infin_evolutiongamingevolutiongr_american_roulette_pl5.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
           
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>
        <div class="col">
          <div class="cartGame ">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/infin_evolutiongamingevolutiongr_power_blackjack_pl5.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
              <button
                class="cartGame__button__two"
                onclick="window.location.href='/jetx3'"
              >
                Демо
              </button>
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>
        <div class="col">
          <div class="cartGame ">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/infin_evolutiongamingevolutiongr_american_roulette_pl5.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
             
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>
        <div class="col">
          <div class="cartGame ">
            <div class="cartGame__block">
              <img
                class="cartGame__block__img"
                src="@/assets/img/LiveRouletteArea/infin_evolutiongamingevolutiongr_american_roulette_pl5.avif"
              />
            </div>
            <div class="cartGame__button_position font">
              <button class="cartGame__button">Играть</button>
          
            </div>
            <div class="cartGame__nameGame">Balloon</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="buttonNext">
            <button class="buttonNext__style">
              More games

              <img
                class="imgStyle"
                src=""
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.BestGameArea {
  position: relative;
  margin: 30px 0;
  z-index: 1;
  padding: 0px 16px 0 16px  ;
}

.banner__title {
  padding-top: 0%;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
}

.cartGame {
  padding-top: 35px;
  position: relative;
  margin-bottom: 10px;
  width: 100%;

  display: flex; /* Добавлено */
  flex-direction: column; /* Добавлено */
  align-items: center;
  margin: auto;
  

  &__block {
    // position: relative;

    &__img {
      max-width: 100%;
      height: auto;
      cursor: pointer;
      border-radius: 19px;

      &:hover {
        filter: blur(3px);
        transition: 0.3s;
      }
    }
  }

  &__nameGame {
    text-align: left;
    padding-top: 20px;

    font-weight: 700;
    font-size: 20px;

    align-self: start;
  }
}

@media (max-width: 500px) {
  .cartGame {
    width: 150px;
  }
}

.cartGame:hover > .cartGame__block {
  filter: blur(3px);
  transition: 0.3s;
}

.cartGame__button_position {
  align-items: center;
}

.cartGame__button {
  position: absolute;
  width: 92%;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  margin: 80px 0px;
  background-color: #7647dc;
  box-shadow: 0 0 15px #7647dc;
  border: none;
  color: #fff;
  border-radius: 19px;
  font-weight: 900;
  font-size: 14px;

  opacity: 0;
  transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.cartGame__button__two {
  position: absolute;
  width: 92%;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: none;
  color: #7647dc;
  border: none;
  border-radius: 19px;
  font-weight: 900;
  font-size: 14px;
  margin: 30px 0px;

  opacity: 0;
  transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.cartGame:hover button {
  bottom: 30px;
  opacity: 1;
}

.font {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: bold;
}

.buttonNext {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}

.buttonNext__style {
  border: none;
  padding: 14.5px 28.5px;
  background-color: #7647dc;
  color: #fff;
  border-radius: 19px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

.buttonNext__style:hover {
  background-color: #7445d7 !important;
  // border: none solid #7445d7 !important;

  -webkit-box-shadow: 0px 3px 20px 0px #7445d7;
  -moz-box-shadow: 0px 3px 20px 0px #7445d7;
  box-shadow: 0px 3px 20px 0px #7445d7;
}

.imgStyle {
  padding-left: 12px;
  margin: auto;
}
</style>
