<template>
  <div class="promo">
    <div class="Title">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="titleIcoPosition">
              <div>
                <img
                  class="titleIco"
                  src="@/assets/img/PromoArea/gift.svg"
                  alt=""
                />
              </div>
              <div class="banner">
                <h2 class="banner__title text-white">Акции</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container text-white">
      <div class="row">
        <div class="col-xl-12">
          <div class="background__cart">
            <div class="row">
              <div class="col-xl-12">
                <div class="text">
                  <div class="text__position">
                    <h2 class="text__title">Create your own casino</h2>
                  </div>
                  <div class="text__positionDesc">
                    <h2 class="text__desc">
                      Create a casino with over 50% conversion rate🔥
                    </h2>
                  </div>
                </div>
                <div class="buttonSection">
                  <div class="button__cart">
                    <button
                      class="button__cart__style"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#regModal"
                    >
                      Add a casino 🎰
                    </button>
                  </div>
                  <div
                    class="icon__option"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <button type="button" class="button__style">
                      More details 🃏
                    </button>
                  </div>
                </div>
                <div class="chips">
                  <div class="chips-possition">
                    <img
                      class="chips-possition-img"
                      src="@/assets/img/PromoArea/chips2.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL-->

  <div class="loyalty">
    <div class="row">
      <div class="col">
        <!-- Button trigger modal -->
        <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
          Читать дальше
        </button> -->
        <!-- Modal one -->
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5> -->
                <img
                  class="modal-header-wrapper"
                  src="@/assets/img/loyalty/loyalty-info-bg.jpg"
                  alt=""
                />
                <div class="img-ico">
                  <div class="img-ico-position1">
                    <img
                      class="img-ico-position1-possion"
                      src="@/assets/img/PromoArea/freerounds.svg"
                      alt=""
                    />
                    <div class="img-ico-position1-discount">200</div>
                  </div>
                </div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="contianer">
                  <div class="row">
                    <div class="col-xl-10 col-md-10 col-sm-11 col-11 mx-auto">
                      <h3 class="modal-body-title">
                        Пятничное безумие фонда Депозит 100$ получи 240 FS
                      </h3>

                      <div class="modal-body-desc">
                        <div class="modal-body-desc-title">
                          Получите 240 бесплатных вращений!
                        </div>
                        <div class="modal-body-desc-space">
                          “Friday Fund Frenzy” Описание и использование - Эта
                          акция будет доступна для всех пользователей каждую
                          пятницу мая. Игроки получат определенное количество
                          бесплатных вращений после внесения депозита следующим
                          образом: депозит в размере 20 долларов США - 60
                          бесплатных вращений; Депозит 50$ - 140 фриспинов;
                          Депозит 100$ – 240 фриспинов; Депозит 200$ - 500
                          фриспинов. Для бесплатных вращений требуется отыгрыш
                          20, и они будут разыграны на Sweet Bonanza.
                        </div>
                        <div>
                          Важно помнить, что требования к отыгрышу в играх
                          различаются, причем одни вносят больший вклад, чем
                          другие. Вот список взносов для выполнения требований
                          по отыгрышу для этих типов игр: Слоты для взносов в
                          играх 100%; Скретч-карты 100%; Покер (Live Casino и
                          RNG) 10%; Блэкджек (Live Casino и RNG) 10%; Рулетка
                          (Live Casino и RNG) 10%; Баккара/Пунто Банко (Live
                          Casino и RNG) 10%; Виртуальный спорт 10%; Слоты с
                          джекпотом 0%; Другие/исключенные игры 0%.
                        </div>
                        <div class="modal-body-desc-Headertext">
                          Общие правила и условия бонусов
                        </div>
                        <div class="modal-body-desc-space">
                          Использование бонусов - Когда пользователь принимает
                          бонус, он сначала будет играть со своим депозитом, а
                          затем со своими бонусными деньгами/вращениями.
                          Выигрыши могут быть сняты только после того, как будет
                          выполнено требование по отыгрышу, указанное в
                          настоящих Условиях. Если вывод средств запрашивается
                          до выполнения требования по отыгрышу, депозитные
                          средства могут быть сняты, но выигрыш автоматически
                          аннулируется.
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-center">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Закрыть
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="exampleModal-2"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5> -->
                <img
                  class="modal-header-wrapper"
                  src="@/assets/img/loyalty/loyalty-info-bg.jpg"
                  alt=""
                />
                <div class="img-ico">
                  <div class="img-ico-position1">
                    <img
                      class="img-ico-position1-possion"
                      src="@/assets/img/PromoArea/freerounds.svg"
                      alt=""
                    />
                    <div class="img-ico-position1-discount">210</div>
                  </div>
                </div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="contianer">
                  <div class="row">
                    <div class="col-xl-10 col-md-10 col-sm-11 col-11 mx-auto">
                      <h3 class="modal-body-title">
                        Пятничное безумие фонда Депозит 100$ получи 240 FS
                      </h3>

                      <div class="modal-body-desc">
                        <div class="modal-body-desc-title">
                          Получите 240 бесплатных вращений!
                        </div>
                        <div class="modal-body-desc-space">
                          “Friday Fund Frenzy” Описание и использование - Эта
                          акция будет доступна для всех пользователей каждую
                          пятницу мая. Игроки получат определенное количество
                          бесплатных вращений после внесения депозита следующим
                          образом: депозит в размере 20 долларов США - 60
                          бесплатных вращений; Депозит 50$ - 140 фриспинов;
                          Депозит 100$ – 240 фриспинов; Депозит 200$ - 500
                          фриспинов. Для бесплатных вращений требуется отыгрыш
                          20, и они будут разыграны на Sweet Bonanza.
                        </div>
                        <div>
                          Важно помнить, что требования к отыгрышу в играх
                          различаются, причем одни вносят больший вклад, чем
                          другие. Вот список взносов для выполнения требований
                          по отыгрышу для этих типов игр: Слоты для взносов в
                          играх 100%; Скретч-карты 100%; Покер (Live Casino и
                          RNG) 10%; Блэкджек (Live Casino и RNG) 10%; Рулетка
                          (Live Casino и RNG) 10%; Баккара/Пунто Банко (Live
                          Casino и RNG) 10%; Виртуальный спорт 10%; Слоты с
                          джекпотом 0%; Другие/исключенные игры 0%.
                        </div>
                        <div class="modal-body-desc-Headertext">
                          Общие правила и условия бонусов
                        </div>
                        <div class="modal-body-desc-space">
                          Использование бонусов - Когда пользователь принимает
                          бонус, он сначала будет играть со своим депозитом, а
                          затем со своими бонусными деньгами/вращениями.
                          Выигрыши могут быть сняты только после того, как будет
                          выполнено требование по отыгрышу, указанное в
                          настоящих Условиях. Если вывод средств запрашивается
                          до выполнения требования по отыгрышу, депозитные
                          средства могут быть сняты, но выигрыш автоматически
                          аннулируется.
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-center">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Закрыть
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="exampleModal-3"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5> -->
                <img
                  class="modal-header-wrapper"
                  src="@/assets/img/loyalty/loyalty-info-bg.jpg"
                  alt=""
                />
                <div class="img-ico">
                  <div class="img-ico-position1">
                    <img
                      class="img-ico-position1-possion"
                      src="@/assets/img/PromoArea/freerounds.svg"
                      alt=""
                    />
                    <div class="img-ico-position1-discount">300</div>
                  </div>
                </div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="contianer">
                  <div class="row">
                    <div class="col-xl-10 col-md-10 col-sm-11 col-11 mx-auto">
                      <h3 class="modal-body-title">
                        Пятничное безумие фонда Депозит 100$ получи 240 FS
                      </h3>

                      <div class="modal-body-desc">
                        <div class="modal-body-desc-title">
                          Получите 240 бесплатных вращений!
                        </div>
                        <div class="modal-body-desc-space">
                          “Friday Fund Frenzy” Описание и использование - Эта
                          акция будет доступна для всех пользователей каждую
                          пятницу мая. Игроки получат определенное количество
                          бесплатных вращений после внесения депозита следующим
                          образом: депозит в размере 20 долларов США - 60
                          бесплатных вращений; Депозит 50$ - 140 фриспинов;
                          Депозит 100$ – 240 фриспинов; Депозит 200$ - 500
                          фриспинов. Для бесплатных вращений требуется отыгрыш
                          20, и они будут разыграны на Sweet Bonanza.
                        </div>
                        <div>
                          Важно помнить, что требования к отыгрышу в играх
                          различаются, причем одни вносят больший вклад, чем
                          другие. Вот список взносов для выполнения требований
                          по отыгрышу для этих типов игр: Слоты для взносов в
                          играх 100%; Скретч-карты 100%; Покер (Live Casino и
                          RNG) 10%; Блэкджек (Live Casino и RNG) 10%; Рулетка
                          (Live Casino и RNG) 10%; Баккара/Пунто Банко (Live
                          Casino и RNG) 10%; Виртуальный спорт 10%; Слоты с
                          джекпотом 0%; Другие/исключенные игры 0%.
                        </div>
                        <div class="modal-body-desc-Headertext">
                          Общие правила и условия бонусов
                        </div>
                        <div class="modal-body-desc-space">
                          Использование бонусов - Когда пользователь принимает
                          бонус, он сначала будет играть со своим депозитом, а
                          затем со своими бонусными деньгами/вращениями.
                          Выигрыши могут быть сняты только после того, как будет
                          выполнено требование по отыгрышу, указанное в
                          настоящих Условиях. Если вывод средств запрашивается
                          до выполнения требования по отыгрышу, депозитные
                          средства могут быть сняты, но выигрыш автоматически
                          аннулируется.
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-center">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Закрыть
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="exampleModal-4"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5> -->
                <img
                  class="modal-header-wrapper"
                  src="@/assets/img/loyalty/loyalty-info-bg.jpg"
                  alt=""
                />
                <div class="img-ico">
                  <div class="img-ico-position1">
                    <img
                      class="img-ico-position1-possion"
                      src="@/assets/img/PromoArea/freerounds.svg"
                      alt=""
                    />
                    <div class="img-ico-position1-discount">400</div>
                  </div>
                </div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="contianer">
                  <div class="row">
                    <div class="col-xl-10 col-md-10 col-sm-11 col-11 mx-auto">
                      <h3 class="modal-body-title">
                        Пятничное безумие фонда Депозит 100$ получи 240 FS
                      </h3>

                      <div class="modal-body-desc">
                        <div class="modal-body-desc-title">
                          Получите 240 бесплатных вращений!
                        </div>
                        <div class="modal-body-desc-space">
                          “Friday Fund Frenzy” Описание и использование - Эта
                          акция будет доступна для всех пользователей каждую
                          пятницу мая. Игроки получат определенное количество
                          бесплатных вращений после внесения депозита следующим
                          образом: депозит в размере 20 долларов США - 60
                          бесплатных вращений; Депозит 50$ - 140 фриспинов;
                          Депозит 100$ – 240 фриспинов; Депозит 200$ - 500
                          фриспинов. Для бесплатных вращений требуется отыгрыш
                          20, и они будут разыграны на Sweet Bonanza.
                        </div>
                        <div>
                          Важно помнить, что требования к отыгрышу в играх
                          различаются, причем одни вносят больший вклад, чем
                          другие. Вот список взносов для выполнения требований
                          по отыгрышу для этих типов игр: Слоты для взносов в
                          играх 100%; Скретч-карты 100%; Покер (Live Casino и
                          RNG) 10%; Блэкджек (Live Casino и RNG) 10%; Рулетка
                          (Live Casino и RNG) 10%; Баккара/Пунто Банко (Live
                          Casino и RNG) 10%; Виртуальный спорт 10%; Слоты с
                          джекпотом 0%; Другие/исключенные игры 0%.
                        </div>
                        <div class="modal-body-desc-Headertext">
                          Общие правила и условия бонусов
                        </div>
                        <div class="modal-body-desc-space">
                          Использование бонусов - Когда пользователь принимает
                          бонус, он сначала будет играть со своим депозитом, а
                          затем со своими бонусными деньгами/вращениями.
                          Выигрыши могут быть сняты только после того, как будет
                          выполнено требование по отыгрышу, указанное в
                          настоящих Условиях. Если вывод средств запрашивается
                          до выполнения требования по отыгрышу, депозитные
                          средства могут быть сняты, но выигрыш автоматически
                          аннулируется.
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-center">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Закрыть
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.titleIcoPosition {
  display: flex;
}

.banner {
  padding-left: 18px;
}

.banner__title {
  font-size: 32px;
  font-weight: 700;
  text-align: start;
  padding-bottom: 47px;
}

.titleIco {
  width: 35px;
}

@media (max-width: 1200px) {
  .titleIco {
    width: 25px;
  }
}

.promo {
  margin: 47px 0 47px 0;
}

.background__cart {
  margin-bottom: 74px;
  background: linear-gradient(84deg, #131420 33.85%, #7647dc 100%);

  width: 100%;
  height: 339px;
  border-radius: 19px;
  position: relative;
  padding: 40px 40px;
}

.text {
  &__title {
    text-align: left;
    font-size: 14px;

    font-weight: 500;

    text-transform: uppercase;
  }

  &__desc {
    text-align: left;

    font-size: 32px;

    font-weight: 700;
    width: 431px;
  }
}

.icon__option {
  padding-left: 20px;
  cursor: pointer;
}

.icon__option:hover {
  fill: azure;
}

.button__cart {
  &__style {
    background: #7647dc;
    border: 2px solid #7647dc;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;

    border-radius: 20px;
    width: 152px;
    height: 48px;
  }

  &__style:hover {
    color: #fff;
    background-color: #7647dc !important;
    border: 2px solid #7647dc !important;

    -webkit-box-shadow: 0px 3px 20px 0px #7647dc;
    -moz-box-shadow: 0px 3px 20px 0px #7647dc;
    box-shadow: 0px 3px 20px 0px #7647dc;
  }
}

.background {
  color: #112a34;
}

.button__style {
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  width: 144px;
  background-color: transparent;
  border: none;
}

.text__position {
  padding-bottom: 60px;
}

.buttonSection {
  display: flex;
  padding-top: 48px;
}

//modal

.loyalty {
  // background-image: url(@/assets/img/loyalty/bg.jpg);
  // background-position: center;
  // background-size: cover;
  color: #fff;
  // padding: 40px 0;

  &__card {
    &-icon {
      &__img {
        background: #4f6b77;
        border-radius: 50%;
      }
    }

    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 1.45;
      margin: 20px 0 0 0;
    }

    &-quantity {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      line-height: 1.45;

      svg {
        margin-left: 5px;
      }
    }
  }

  .modal-header-wrapper {
    width: 100%;
    border-radius: 4px 4px 0 0;
  }

  .img-ico {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &-position1 {
      &-possion {
        position: relative;
      }

      &-discount {
        position: absolute;
        background-color: #1f3842;
        border-radius: 20px;
        font-size: 2.5rem;
        font-weight: 600;
        width: 70px;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .modal-header {
    padding: 0;
    position: relative;
  }

  .modal-content {
    background-color: #132932;
  }

  .modal-body-title {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.45;
    padding: 20px 0 20px 0;
  }

  @media (max-width: 768px) {
    .modal-body-title {
      font-size: 1.7rem;
    }
  }

  .modal-body-desc {
    text-align: left;
    font-size: 1.7rem;
    line-height: 2.5rem;
    white-space: pre-line;

    &-title {
      text-align: center;
      padding: 20px 0;
      font-weight: 800;
    }

    &-Headertext {
      font-weight: 800;
      text-align: left;
      padding: 20px 0 20px 0;
    }

    &-space {
      padding-bottom: 20px;
    }

    @media (max-width: 768px) {
      font-size: 1.3rem;
    }
  }

  // .table__head {
  //   display: flex;
  // }
  .table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    font-style: normal;
    line-height: 1.45;
    padding: 20px 10px;
  }

  .table__body .table-row:nth-child(2n + 1) {
    background-color: #1f3842;
    border-radius: 10px;
  }

  // .table__body-cell{
  //   padding: 20px 10px;
  // }
  .btn-close {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .btn-secondary {
    background-color: #d4145a;
    border: 2px solid #d4145a;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    width: 144px;
  }

  .btn-secondary:hover {
    color: #fff;
    background-color: #d4145a !important;
    border: 2px solid #d4145a !important;

    -webkit-box-shadow: 0px 3px 20px 0px #d4145a;
    -moz-box-shadow: 0px 3px 20px 0px #d4145a;
    box-shadow: 0px 3px 20px 0px #d4145a;
  }
}

.chips {
  position: relative;

  &-possition {
    position: absolute;
    right: 0;
    bottom: -88px;

    &-img {
      width: 508px;
    }

   
  }
}



@media (max-width:1200px) {

.chips-possition {
  right: 0;
  bottom: -44px;
}
  .chips-possition-img {
    width: 450px;
  }
}


@media (max-width:1000px) {

.chips-possition {
  display: none;
}
  
}


@media (max-width:500px) {

.text__desc {
  font-size: 22px;
}
  
}
</style>
